import React from 'react';
import {
  BooleanField,
  Button,
  EditButton,
  Grid,
  List,
  ListButton,
  Show,
  Space,
  Table,
  TextField,
  Typography,
  useTable,
  ShowButton,
} from '@pankod/refine-antd';
import { IAccount, Order } from '../../types';
import { useNavigation } from '@pankod/refine-core';
import dayjs from 'dayjs';
import { DetailsWrapper, ColumnWrapper, Separator } from '../../App';
import colors from '../../ Theme/ Colors';
import { handleRequestDeleteRecord } from '../../ Utils/HelperFunctions';
import { BsTrash } from 'react-icons/bs';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const ShowUser = () => {
  const screens = useBreakpoint();
  const { show } = useNavigation();

  const id = +window.location.href.split('show/')[1];
  const users = useTable<IAccount[]>({
    resource: `account-cruds?id=${id}`,
  });
  const { tableProps } = useTable<Order[]>({
    resource: `order-cruds?user_id=${id}`,
  });
  const reviews = useTable<Order[]>({
    resource: `review-cruds?account_id=${id}`,
  });

  const handleNavigate = (name: string, id: number) => () => {
    show(name, id);
  };

  const record: any =
    users?.tableProps?.dataSource && users?.tableProps?.dataSource[0];

  return (
    <>
      <Show
        isLoading={users?.tableQueryResult?.isLoading}
        pageHeaderProps={{
          extra: (
            <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
              <ListButton />
              <EditButton />
              {record?.status === 'active' && (
                <Button
                  icon={
                    <BsTrash style={{ marginBottom: -2, marginRight: 5 }} />
                  }
                  onClick={() =>
                    handleRequestDeleteRecord(
                      record?.id || 0,
                      users?.tableQueryResult?.refetch
                    )
                  }
                >
                  Delete Account
                </Button>
              )}
              <ShowButton />
            </Space>
          ),
        }}
      >
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>ID</Title>
            <Text>{record?.id || '-'}</Text>
            <Title level={5}>Name</Title>
            <Text>{record?.name || '-'}</Text>
            <Title level={5}>Gender</Title>
            <Text>{record?.gender || '-'}</Text>
            <Title level={5}>Phone</Title>
            <Text>{record?.phone || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Account Type</Title>
            <Text>{record?.account_type || '-'}</Text>
            <Title level={5}>Points</Title>
            <Text>{record?.points || '-'}</Text>
            <Title level={5}>Average Rate</Title>
            <Text>{record?.average_rate || '-'}</Text>
            <Title level={5}>Status</Title>
            <Text>{record?.status || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Email Confirmed</Title>
            <BooleanField value={record?.is_email_confirmed} />
            <Title level={5}> Phone Confirmed</Title>
            <BooleanField value={record?.is_phone_confirmed} />

            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>Dob</Title>
            <Text>
              {record?.dob
                ? dayjs(record?.dob).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Updated At</Title>
            <Text>
              {record?.updated_at
                ? dayjs(record?.updated_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>

      <Separator />

      <List
        title={`${'Orders'}`}
        pageHeaderProps={{
          extra: <div style={{ display: 'flex', flexDirection: 'row' }}></div>,
        }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title={'ID'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={['provider_id']}
            title={'Provider'}
            render={(value) => (
              <TextField
                value={`Provider#${value}`}
                style={{ color: colors.blue100, cursor: 'pointer' }}
                onClick={handleNavigate('provider-cruds', value)}
              />
            )}
          />
          {/* <Table.Column
            dataIndex="actual_cost"
            title={'Actual Cost'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'actual_time'}
            title={'Actual Time'}
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex="contact_number"
            title={'Contact Number'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="contact_person"
            title={'Contact Person'}
            render={(value) => <TextField value={value || '-'} />}
          /> */}
          <Table.Column
            dataIndex="status"
            title={'Status'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="cost_estimation"
            title={'Cost Estimation'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="order_type"
            title={'Order Type'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="provider_type"
            title={'Provider Type'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="payment_method"
            title={'Payment Method'}
            render={(value) => (
              <TextField
                value={
                  value === 'cod'
                    ? 'Cash on delivery'
                    : value === 'card'
                    ? 'Card'
                    : '-'
                }
              />
            )}
          />
          <Table.Column
            dataIndex={['rewarded_points']}
            title={'Rewarded Points'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="shopping_cost_estimation"
            title={'Shopping Cost Estimation'}
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex="time_estimation"
            title={'Time Estimation'}
            render={(value) => <TextField value={`${value} mins` || '-'} />}
          />
          <Table.Column
            dataIndex="total_distance"
            title={'Total Distance'}
            render={(value) => <TextField value={`${value} m` || '-'} />}
          />

          <Table.Column<any>
            title={'Actions'}
            dataIndex="actions"
            fixed="right"
            key="actions"
            render={(_text, record): any => {
              return (
                <ShowButton
                  hideText
                  size="small"
                  onClick={handleNavigate('order-cruds', record.id)}
                />
              );
            }}
          />
        </Table>
      </List>

      <Separator />

      <List
        title={`${'Reviews'}`}
        pageHeaderProps={{
          extra: <div style={{ display: 'flex', flexDirection: 'row' }}></div>,
        }}
      >
        <Table {...reviews.tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title={'ID'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={['created_by', 'name']}
            title={"Reporter's name"}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={['account', 'name']}
            title={"Defendant's name"}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'title'}
            title={'Title'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'comment'}
            title={'comment'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'is_report'}
            title={'Is Report'}
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column<any>
            title={'Actions'}
            dataIndex="actions"
            fixed="right"
            align="center"
            key="actions"
            render={(_text, record): any => {
              return (
                <ShowButton
                  hideText
                  size="small"
                  onClick={handleNavigate('review-cruds', record.id)}
                />
              );
            }}
          />
        </Table>
      </List>
    </>
  );
};
