import { notification } from '@pankod/refine-antd';
import { AccountStore, ConfigsStore } from '../ Store';
import { apiRequest } from '../ Utils/APIs';
import { Language, OsEnum } from '../types';
import { LogoutAction } from './AuthActions';
import Swal from 'sweetalert2';
export const CreateDeviceAction = async () => {
  const currentDevice = await ConfigsStore.get('device_id');
  if (currentDevice) return;
  try {
    const { data: deviceData } = await apiRequest<{ id: string }>({
      url: 'device-cruds',
      method: 'POST',
      data: {
        os: OsEnum.browser,
        language: Language.ar,
      },
    });
    if (!deviceData) throw new Error('Something went wrong!');
    await ConfigsStore.set('device_id', deviceData?.id);
  } catch (error: any) {
    throw new Error('Error');
  }
};
export const EditRecordAction = async (
  type: string,
  id: any,
  data: any,
  refetch?: () => void,
  withRefresh?: boolean,
  resetForm?: () => void,
  withoutAlert?: boolean
) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}/${id}`,
      method: 'PATCH',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens.access_token
        }`,
      },
    });
    resetForm && resetForm();
    refetch && refetch();
    !withoutAlert &&
      notification.success({
        message: 'Success',
        description: 'Record updated successfully',
      });
    withRefresh && window.location.reload();
  } catch (error: any) {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      return LogoutAction(true);
    }
    notification.error({ message: 'Error', description: error?.message });
  }
};
export const CreateRecordAction = async (
  url: string,
  data: any,
  navigateToEdit?: (url: string, id?: string) => void,
  goBack?: () => void,
  refetch?: () => void
) => {
  try {
    const { data: res } = await apiRequest<{ id: any }>({
      url,
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens.access_token
        }`,
      },
    });
    if (res) {
      goBack && goBack();
      navigateToEdit && navigateToEdit(url, res?.id);
      refetch && refetch();
      notification.success({
        message: 'Success',
        description: 'Record created successfully',
      });
    }
  } catch (error: any) {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      return LogoutAction(true);
    }
    notification.error({
      message: 'Error',
      description: 'Error in creating record',
    });
  }
};

export const GetOneRecordAction = async (
  type: string,
  id: number,
  setValue?: (val: any) => void
) => {
  try {
    const { data } = await apiRequest<boolean>({
      url: `/${type}/${id}`,
      method: 'GET',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens.access_token
        }`,
      },
    });
    setValue && setValue(data);
  } catch (error: any) {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      return LogoutAction(true);
    }
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const ApproveDocumentAction = async (
  document_id: number,
  account_id: number,
  refetch?: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: `/provider-documents-cruds/approve-document/${account_id}`,
      method: 'PATCH',
      data: { document_id },
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens?.access_token
        }`,
      },
    });
    refetch && refetch();
    notification.success({
      message: 'Success',
      description: 'Document approved successfully',
    });
  } catch (error: any) {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      return LogoutAction(true);
    }
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const RejectDocumentAction = async (
  document_id: number,
  refetch: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: `/provider-documents-cruds/reject-document/${document_id}`,
      method: 'PATCH',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens?.access_token
        }`,
      },
    });
    refetch && refetch();
    notification.success({
      message: 'Success',
      description: 'Document rejected successfully',
    });
  } catch (error: any) {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      return LogoutAction(true);
    }
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const SearchAction = async (
  url: string,
  setSearchResults: (val: any) => void,
  loading: (val: boolean) => void
) => {
  try {
    loading(true);
    const { data } = await apiRequest<boolean>({
      url,
      method: 'GET',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens?.access_token
        }`,
      },
    });
    setSearchResults(data || []);
    loading(false);
  } catch (error: any) {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      return LogoutAction(true);
    }
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const SendMessageAction = async (
  data: {
    sender_id?: number;
    thread_id: number;
    body: string;
    type: string;
    upload_id?: number;
    is_read?: boolean;
  },
  refetch?: () => void,
  stopLoading?: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: '/Message-cruds/create-message',
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens?.access_token
        }`,
      },
    });
    stopLoading && stopLoading();
    refetch && refetch();
  } catch (error: any) {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      return LogoutAction(true);
    }
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const GetThreadAction = async (
  id: number,
  handleThreads: (val: any) => void
) => {
  const { data: threads } = await apiRequest<any>({
    url: `message-cruds?thread_id=${id}`,
    method: 'GET',
  });
  handleThreads(threads);
};

export const MarkMessagesAsReadAction = async (messages_ids: number[]) => {
  try {
    await apiRequest<boolean>({
      url: `message-cruds/mark-as-read`,
      method: 'POST',
      data: { messages_ids },
    });
  } catch (error: any) {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      return LogoutAction(true);
    }
    return;
  }
};

export const ApproveRequestDeletionAction = async (
  data: { account_id?: number },
  refetch?: () => void,
  stopLoading?: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: '/account-cruds/delete',
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens?.access_token
        }`,
      },
    });
    notification.success({
      message: 'Success',
      description: 'Record deleted successfully',
    });
    stopLoading && stopLoading();
    refetch && refetch();
  } catch (error: any) {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      return LogoutAction(true);
    }
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const UnoccupyDriverAction = async (id: number, refetch: () => void) => {
  Swal.fire({
    title: 'Are you sure?',
    showCancelButton: true,
    confirmButtonColor: '#db2828',
    confirmButtonText: 'Accept',
    cancelButtonText: 'Cancel',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const { data } = await apiRequest<string>({
          url: `/provider-cruds/unoccupy-driver/${id}`,
          method: 'GET',
          headers: {
            device_id: await ConfigsStore.get('device_id'),
            authorization: `Bearer ${
              (
                await AccountStore.get('account')
              )?.tokens?.access_token
            }`,
          },
        });

        if (data === 'No Orders To Cancel') {
          notification.info({
            message: 'info',
            description: 'No Orders To Cancel',
          });
        } else {
          refetch();
          notification.success({
            message: 'Success',
            description: 'Driver unoccupied successfully',
          });
        }
      } catch (error: any) {
        if (error?.message.includes('401') || error?.message.includes('403')) {
          return LogoutAction(true);
        }
        return;
      }
    }
  });
};
