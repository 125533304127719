import React, { useEffect, useState } from 'react';
import { Refine } from '@pankod/refine-core';
import {
  Layout,
  ReadyPage,
  notificationProvider,
  ErrorComponent,
} from '@pankod/refine-antd';
import routerProvider from '@pankod/refine-react-router-v6';
import '@pankod/refine-antd/dist/styles.min.css';
import { API_URL } from './configs';
import { UserListing } from './ Containers/User/ UserListing';
import { AiOutlineUser, AiOutlineMessage } from 'react-icons/ai';
import { RiAdvertisementLine, RiAdminLine } from 'react-icons/ri';
import { BiCategory, BiSupport } from 'react-icons/bi';
import { GoListOrdered } from 'react-icons/go';
import { GiBowTieRibbon, GiWantedReward } from 'react-icons/gi';
import { IoMdSettings } from 'react-icons/io';
import { MdOutlineReviews } from 'react-icons/md';
import { FaHandsHelping } from 'react-icons/fa';
import { ShowUser } from './ Containers/User/ShowUser';
import styled from 'styled-components';
import dataProvider from './Providers/DataProvider';
import { EditUser } from './ Containers/User/EditUser';
import { AdvertisementListing } from './ Containers/Advertisement/AdvertisementListing';
import { ShowAdvertisement } from './ Containers/Advertisement/ShowAdvertisement';
import { EditAdvertisement } from './ Containers/Advertisement/EditAdvertisement';
import { CreateAdvertisement } from './ Containers/Advertisement/CreateAdvertisement';
import { TierListing } from './ Containers/Tier/TierListing';
import { ShowTier } from './ Containers/Tier/ShowTier';
import { EditTier } from './ Containers/Tier/EditTier';
import { CreateTier } from './ Containers/Tier/CreateTier';
import { axiosInstance } from './Actions/AuthActions';
import { CategoryListing } from './ Containers/Category/CategoryListing';
import { ShowCategory } from './ Containers/Category/ShowCategory';
import { EditCategory } from './ Containers/Category/EditCategory';
import { CreateCategory } from './ Containers/Category/CreateCategory';
import { RewardListing } from './ Containers/Reward/RewardListing';
import { ShowReward } from './ Containers/Reward/ShowReward';
import { EditReward } from './ Containers/Reward/EditReward';
import { CreateReward } from './ Containers/Reward/CreateReward';
import { Login } from './ Containers/ Login/ Login';
import authProvider from './Providers/IAuthProvider';
import { CreateDeviceAction } from './Actions/ConfigsActions';
import { ForgotPassword } from './ Containers/ AuthPages/ ForgotPassword/ index';
import { ConfigsStore } from './ Store';
import { Verification } from './ Containers/ AuthPages/Verification';
import { ResetPassword } from './ Containers/ AuthPages/ResetPassword/ index';
import { ProviderListing } from './ Containers/Provider/ProviderListing';
import { ShowProvider } from './ Containers/Provider/ShowProvider';
import { EditProvider } from './ Containers/Provider/EditProvider';
import { SettlementListing } from './Settlement/SettlementListing';
import { ShowSettlement } from './Settlement/ShowSettlement';
import { EditSettlement } from './Settlement/EditSettlement';
import { ReviewListing } from './Review/ReviewListing';
import { ShowReview } from './Review/ShowReview';
import { SystemConfigList } from './System Config/SystemConfigList';
import { EditSystemConfig } from './System Config/EditSystemConfig';
import { OrderListing } from './ Containers/Order/OrderListing';
import { ShowOrder } from './ Containers/Order/ShowOrder';
import { EditOrder } from './ Containers/Order/EditOrder';
import { AdminListing } from './ Containers/admin/AdminListing';
import { CreateAdmin } from './ Containers/admin/CreateAdmin';
import { EditAdmin } from './ Containers/admin/EditAdmin';
import { ShowAdmin } from './ Containers/admin/ShowAdmin';
import { HelpCenterListing } from './ Containers/HelpCenter/HelpCenterListing';
import { OrderStops } from './ Containers/Order/Orderstops';
import logo from './ images/logo.png';
import * as Sentry from '@sentry/react';

const { RouterComponent } = routerProvider;
const CustomRouterComponent = () => <RouterComponent basename="/" />;
const App = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>();
  const [roles, setRoles] = useState<'admin' | 'super_admin'>();
  const CustomLoginPage = () => <Login />;
  const routing = {
    ...routerProvider,
    RouterComponent: CustomRouterComponent,
    routes: [
      {
        exact: true,
        element: loggedIn ? <ErrorComponent /> : <ForgotPassword />,
        path: '/forgot-password',
      },
      {
        exact: true,
        element: loggedIn ? <ErrorComponent /> : <Verification />,
        path: '/verification',
      },
      {
        exact: true,
        element: loggedIn ? <ErrorComponent /> : <ResetPassword />,
        path: '/reset-password',
      },
      {
        exact: true,
        element: loggedIn ? <OrderStops /> : <ErrorComponent />,
        path: '/order-cruds/order-stop',
      },
    ],
  };
  const getCookie = async () => {
    setLoggedIn(await ConfigsStore.get('logged_in'));
    setRoles(await ConfigsStore.get('adminRoles'));
  };

  useEffect(() => {
    getCookie();
    CreateDeviceAction();
  }, []);

  const resources = allResources?.filter((resource) => {
    if (roles === 'admin') {
      if (resource.name !== 'admin-cruds') {
        return resource;
      }
    } else {
      return resource;
    }
  });

  return (
    <Refine
      Title={({ collapsed }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 15,
            marginBottom: 10,
          }}
        >
          {!collapsed && (
            <img
              style={{ marginBottom: 20, alignSelf: 'center' }}
              src={logo}
              width="85px"
              alt="logo"
            />
          )}
          {collapsed && (
            <img
              style={{ marginBottom: 20, alignSelf: 'center' }}
              src={logo}
              width="50px"
              alt="logo"
            />
          )}
        </div>
      )}
      routerProvider={routing}
      /* @ts-ignore */
      dataProvider={dataProvider(API_URL, axiosInstance)}
      Layout={Layout}
      ReadyPage={ReadyPage}
      LoginPage={CustomLoginPage}
      authProvider={authProvider}
      notificationProvider={notificationProvider}
      catchAll={<ErrorComponent />}
      resources={resources}
    />
  );
};

export default Sentry.withProfiler(App);

export const DetailsWrapper = styled.div<{ screenMD: boolean }>`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.screenMD ? '' : 'space-around')};
`;
export const ColumnWrapper = styled.div<{ screenMD: boolean }>`
  margin: ${(props) => (props.screenMD ? '' : '20px')};
`;

export const Separator = styled.div`
  margin-top: 15px;
`;

export const allResources = [
  {
    name: 'admin-cruds',
    options: { label: 'Admin' },
    list: AdminListing,
    show: ShowAdmin,
    edit: EditAdmin,
    create: CreateAdmin,
    icon: <RiAdminLine style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'user-cruds',
    options: { label: 'Users' },
    list: UserListing,
    show: ShowUser,
    edit: EditUser,
    icon: <AiOutlineUser style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'provider-cruds',
    options: { label: 'Provider' },
    list: ProviderListing,
    show: ShowProvider,
    edit: EditProvider,
    icon: <BiSupport style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'order-cruds',
    options: { label: 'Order' },
    list: OrderListing,
    show: ShowOrder,
    edit: EditOrder,
    icon: <GoListOrdered style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'provider-settlement-cruds',
    options: { label: 'Settlement' },
    list: SettlementListing,
    show: ShowSettlement,
    edit: EditSettlement,
    icon: <FaHandsHelping style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'advertisement-cruds',
    options: { label: 'Advertisement' },
    list: AdvertisementListing,
    show: ShowAdvertisement,
    edit: EditAdvertisement,
    create: CreateAdvertisement,
    icon: <RiAdvertisementLine style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'tier-cruds',
    options: { label: 'Tier' },
    list: TierListing,
    show: ShowTier,
    edit: EditTier,
    create: CreateTier,
    icon: <GiBowTieRibbon style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'category-cruds',
    options: { label: 'Category' },
    list: CategoryListing,
    show: ShowCategory,
    edit: EditCategory,
    create: CreateCategory,
    icon: <BiCategory style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'reward-cruds',
    options: { label: 'Reward' },
    list: RewardListing,
    show: ShowReward,
    edit: EditReward,
    create: CreateReward,
    icon: <GiWantedReward style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'review-cruds',
    options: { label: 'Review' },
    list: ReviewListing,
    show: ShowReview,
    icon: <MdOutlineReviews style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'system-config-cruds',
    options: { label: 'System configs' },
    list: SystemConfigList,
    edit: EditSystemConfig,
    icon: <IoMdSettings style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'order-cruds',
    options: { label: 'Order' },
    list: OrderListing,
    show: ShowOrder,
    edit: EditOrder,
    icon: <GoListOrdered style={{ marginRight: 10 }} size={20} />,
  },
  {
    name: 'thread-cruds',
    options: { label: 'Help Center' },
    list: HelpCenterListing,
    icon: <AiOutlineMessage style={{ marginRight: 10 }} size={20} />,
  },
];
