import React from 'react';
import {
  BooleanField,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { ShowButton } from '@pankod/refine-mui';
import { Review } from '../types';
import useModal from '../hooks/useModal';
import { Search } from '../Components/ Search';
import SearchModal from '../Components/SearchModal';
import DropDownFilter from '../Components/DropDownFilter';
import colors from '../ Theme/ Colors';
import { useNavigation } from '@pankod/refine-core';

export const ReviewListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const { closeModal, isVisible } = useModal();
  const { show } = useNavigation();
  const { tableProps } = useTable<Review>({
    syncWithLocation: true,
  });
  //   const handleExportList = () => {
  //     ExportList(tableProps.dataSource || [], 'Review');
  //   };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  }
  return (
    <List
      title={`${'Review'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="review-cruds"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
          </div>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['created_by', 'name']}
          title={"Reporter's name "}
          render={(value, record: Review) => (
            <TextField
              value={value || '-'}
              style={{ cursor: 'pointer', color: colors.blue100 }}
              onClick={() =>
                show(
                  `${record?.created_by?.account_type}-cruds`,
                  record?.created_by?.id
                )
              }
            />
          )}
        />
        <Table.Column
          dataIndex={['account', 'name']}
          title={"Defendant's name "}
          render={(value, record: Review) => (
            <TextField
              value={value || '-'}
              style={{ cursor: 'pointer', color: colors.blue100 }}
              onClick={() =>
                show(
                  `${record?.account?.account_type}-cruds`,
                  record?.account?.id
                )
              }
            />
          )}
        />

        <Table.Column
          dataIndex={['order_id']}
          title={'Order'}
          render={(value) => (
            <TextField
              value={value ? `Order#${value}` : '-'}
              style={{ cursor: 'pointer', color: colors.blue100 }}
              onClick={() => show(`order-cruds`, value)}
            />
          )}
        />

        <Table.Column
          dataIndex={'title'}
          title={'Title'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'rating'}
          title={'Rating'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'comment'}
          title={'comment'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'order_id'}
          title={'Order'}
          render={(value) => (
            <TextField
              value={value ? `Order#${value}` : '-'}
              style={{ cursor: 'pointer', color: colors.blue100 }}
              onClick={() => show(`order-cruds`, value)}
            />
          )}
        />

        <Table.Column
          dataIndex={'is_report'}
          title={'Is Report'}
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Status'}
              options={[
                { label: 'Review', value: 'false' },
                { label: 'Report', value: 'true' },
              ]}
            />
          )}
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          align="center"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
      <SearchModal visible={isVisible} closeModal={closeModal} type="reward" />
    </List>
  );
};
