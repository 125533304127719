import React from 'react';
import { useFormik } from 'formik';
import { Form, Modal, Select } from '@pankod/refine-antd';
import { EditRecordAction } from '../../Actions/ConfigsActions';
interface Props {
  visible: any;
  setVisible: (val: any) => void;
  isLoading?: boolean;
  record?: any;
  listingData?: any;
  type?: string;
  refresh?: () => void;
}
type Inputs = {
  id?: number;
  role?: string;
};
const CustomModal = ({ visible, setVisible, refresh }: Props) => {
  const { handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      id: visible.id,
      role: '',
    },

    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        id: visible.id,
        role: submittedValues.role,
      };
      if (Object.keys(inputs)?.length > 0) {
        EditRecordAction('admin-cruds', visible.id, inputs, refresh);
        setVisible(false);
      }
    },
  });

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => handleSubmit()}
      width={700}
    >
      <div style={{ marginTop: '20px' }}>
        <Form layout="vertical">
          <Form.Item label="Role">
            <Select
              options={[
                { label: 'super_admin', value: 'super_admin' },
                { label: 'admin', value: 'admin' },
              ]}
              defaultValue={visible?.role}
              onChange={(val) => setFieldValue('role', val)}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default CustomModal;
