import * as React from 'react';
import { Edit, Form, Input, useForm } from '@pankod/refine-antd';
import { Category } from '../../types';
export const EditCategory = () => {
  const { formProps, saveButtonProps } = useForm<Category>();
  return (
    <Edit saveButtonProps={saveButtonProps} title={'Edit Category'}>
      <Form layout="vertical" {...formProps}>
        <Form.Item label="Title" name="title">
          <Input name="title" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input name="description" />
        </Form.Item>
      </Form>
    </Edit>
  );
};
