import React from 'react';
import { BsTrash } from 'react-icons/bs';
import { TbTrashOff } from 'react-icons/tb';
import {
  handleDeleteRecord,
  handleRequestDeleteRecord,
  handleRestoreRecord,
} from '../../ Utils/HelperFunctions';
import { AccountStatus } from '../../types';

type Props = {
  id: number;
  is_deleted?: boolean;
  status?: AccountStatus;
  requested_deletion?: boolean;
  path: string;
  handleRefetch: () => void;
};

export const DeleteButtons = ({
  handleRefetch,
  id,
  is_deleted,
  path,
  status,
  requested_deletion,
}: Props) => {
  const handleDelete =
    (name: 'delete' | 'restore' | 'request_deletion') => () => {
      const inputs = status
        ? status === AccountStatus.deleted || status === AccountStatus.archived
          ? { status: AccountStatus.active }
          : { status: AccountStatus.archived }
        : { is_deleted: is_deleted ? false : true };
      if (name === 'delete') {
        handleDeleteRecord(id, path, inputs, handleRefetch);
      }
      if (name === 'request_deletion') {
        handleRequestDeleteRecord(id, handleRefetch);
      }
      if (name === 'restore') {
        handleRestoreRecord(id, path, inputs, handleRefetch);
      }
    };
  return (
    <>
      {is_deleted ||
      status === AccountStatus.archived ||
      status === AccountStatus.deleted ? (
        <TbTrashOff
          style={{ marginLeft: 3, marginTop: 5 }}
          size={20}
          color={'#63d20c'}
          className="icons"
          onClick={handleDelete('restore')}
        />
      ) : requested_deletion && AccountStatus.active ? (
        <BsTrash
          style={{ marginLeft: 3, marginTop: 5 }}
          size={18}
          color={'red'}
          className="icons"
          onClick={handleDelete('request_deletion')}
        />
      ) : (
        <BsTrash
          style={{ marginLeft: 3, marginTop: 5 }}
          size={18}
          color={'red'}
          className="icons"
          onClick={handleDelete('delete')}
        />
      )}
    </>
  );
};
