import * as React from 'react';
import { Edit, Form, InputNumber, Switch, useForm } from '@pankod/refine-antd';
import { Settlement } from '../types';
export const EditSettlement = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<Settlement>();
  const record = queryResult?.data?.data;
  return (
    <Edit saveButtonProps={saveButtonProps} title={'Edit Settlement'}>
      <Form layout="vertical" {...formProps}>
        <Form.Item label="Gomint Earning" name="gomint_earning">
          <InputNumber name="gomint_earning" />
        </Form.Item>
        <Form.Item label="Is Deleted" name="is_deleted">
          <Switch defaultChecked={record?.is_deleted} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
