import React, { useEffect } from 'react';
import {
  BooleanField,
  Grid,
  List,
  Show,
  Table,
  TableProps,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { DetailsWrapper, ColumnWrapper, Separator } from '../../App';
import { Order } from '../../types';
import dayjs from 'dayjs';
import Colors from '../../ Theme/ Colors';
import { ShowButton } from '@pankod/refine-mui';
import { useNavigate, useParams } from 'react-router-dom';
import { useNavigation } from '@pankod/refine-core';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

export const ShowOrder = () => {
  const navigate = useNavigate();
  const { show } = useNavigation();
  const params = useParams();
  const [orderStops, setOrderStops] = React.useState<TableProps<any>>();
  const [orderItems, setOrderItems] = React.useState<TableProps<any>>();
  const [reviews, setReviews] = React.useState<TableProps<any>>();

  const screens = useBreakpoint();
  const { tableProps, tableQueryResult } = useTable<Order>({
    resource: `order-cruds?id=${params?.id}`,
  });

  const declinedOrders = useTable({
    resource: `providers-declined-cruds?order_id=${params?.id}`,
  });

  const record = tableProps?.dataSource && tableProps?.dataSource[0];

  const handleNavigate = (name: string, id: number) => () => {
    show(name, id);
  };
  const handleNavigateToOrderStop = (name: string, id: number) => () => {
    navigate(name, { state: id });
  };

  useEffect(() => {
    if (record) {
      setOrderStops({
        ...tableProps,
        dataSource: record?.orderStops || [],
      });
      setOrderItems({
        ...tableProps,
        dataSource: record?.orderItems || [],
      });
      setReviews({
        ...tableProps,
        dataSource: record?.review || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    <>
      <Show isLoading={tableQueryResult.isLoading}>
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Provider</Title>
            <Text
              style={{ color: Colors.blue100, cursor: 'pointer' }}
              onClick={() =>
                record?.provider_id &&
                show('provider-cruds', record?.provider_id)
              }
            >
              {`Provider#${record?.provider_id || '-'}`}
            </Text>
            <Title level={5}>User</Title>
            <Text
              style={{ color: Colors.blue100, cursor: 'pointer' }}
              onClick={() =>
                record?.user_id && show('user-cruds', record?.user_id)
              }
            >
              {`User#${record?.user_id || '-'}`}
            </Text>
            <Title level={5}>Actual Cost</Title>
            <Text>{record?.actual_cost || '-'}</Text>
            <Title level={5}>Actual Time</Title>
            <Text>{record?.actual_time || '-'}</Text>
            <Title level={5}>Contact Person</Title>
            <Text>{record?.contact_person || '-'}</Text>
            <Title level={5}>Contact Number</Title>
            <Text>{record?.contact_number || '-'}</Text>
            <Title level={5}>Cart Description</Title>
            <Text>{record?.cart_description || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Order Type</Title>
            <Text>{record?.order_type || '-'}</Text>
            <Title level={5}>Payment Method</Title>
            <Text>{record?.payment_method || '-'}</Text>
            <Title level={5}>Provider Type</Title>
            <Text>{record?.provider_type || '-'}</Text>
            <Title level={5}>Delivery Fee</Title>
            <Text>{record?.delivery_fee || '-'}</Text>
            <Title level={5}>Service Fee</Title>
            <Text>{record?.service_fee || '-'}</Text>
            <Title level={5}>Total Cost With Fees</Title>
            <Text>{record?.total_cost_with_fees || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Rewarded Points</Title>
            <Text>{record?.rewarded_points || '-'}</Text>
            <Title level={5}>Shopping Cost Estimation</Title>
            <Text>
              {record?.shopping_cost_estimation
                ? Number(record?.shopping_cost_estimation)?.toFixed(2)
                : '-'}
            </Text>
            <Title level={5}>Time Estimation</Title>
            <Text>{record?.time_estimation || '-'}</Text>
            <Title level={5}>Total Distance</Title>
            <Text>
              {record?.total_distance
                ? Number(record?.total_distance)?.toFixed(2)
                : '-'}
            </Text>
            <Title level={5}>Cost Estimation</Title>
            <Text>
              {record?.cost_estimation
                ? Number(record?.cost_estimation)?.toFixed(2)
                : '-'}
            </Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Completed At</Title>
            <Text>
              {record?.completed_at
                ? dayjs(record?.completed_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>Canceled At</Title>
            <Text>
              {record?.canceled_at
                ? dayjs(record?.canceled_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>Dispatched At</Title>
            <Text>
              {record?.dispatched_at
                ? dayjs(record?.dispatched_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>{'Last Status Change At'}</Title>
            <Text>
              {record?.last_status_change_at
                ? dayjs(record?.last_status_change_at).format(
                    'MMMM DD, YYYY HH:MM'
                  )
                : '-'}
            </Text>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>
      <Separator />
      <List title={`${'Order Stops'}`} pageHeaderProps={{ extra: <></> }}>
        <Table {...orderStops} rowKey="id">
          <Table.Column
            dataIndex={'id'}
            title={'Id'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'title'}
            title={'Title'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="type"
            title={'Type'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column<any>
            title={'Coordinates'}
            dataIndex="actions"
            render={(_text, record): any => {
              if (!record?.lat || !record?.lng) {
                return <TextField value={'-'} />;
              }
              return (
                <>
                  <TextField value={`${record?.lat}, `} />
                  <TextField value={record?.lng} />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="invoice_value"
            title={'Invoice value'}
            align="center"
            render={(value) => <TextField value={value || '0'} />}
          />
          <Table.Column
            dataIndex="area"
            title={'Area'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="building"
            title={'Building'}
            align="center"
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex="reached_at"
            title={'Reached at'}
            render={(value) => (
              <TextField
                value={value ? dayjs(value).format('MMMM DD, YYYY HH:MM') : '-'}
              />
            )}
          />
          <Table.Column
            dataIndex="reached"
            title={'Reached'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column<any>
            title={'Actions'}
            dataIndex="actions"
            fixed="right"
            key="actions"
            render={(_text, record): any => {
              return (
                <ShowButton
                  hideText
                  size="small"
                  onClick={handleNavigateToOrderStop('order-stop', record.id)}
                />
              );
            }}
          />
        </Table>
      </List>
      <Separator />
      <List title={`${'Order Items'}`} pageHeaderProps={{ extra: <></> }}>
        <Table {...orderItems} rowKey="id">
          <Table.Column
            dataIndex={'id'}
            title={'Id'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'title'}
            title={'Title'}
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex={'order_stop_id'}
            title={'Order Stop'}
            render={(value) => (
              <TextField
                value={
                  record?.orderStops?.find((item) => item?.id === value)
                    ?.type || '-'
                }
              />
            )}
          />

          <Table.Column
            dataIndex={'quantity'}
            title={'Quantity'}
            align="center"
            render={(value) => <TextField value={value || '0'} />}
          />
          <Table.Column
            dataIndex="purchased"
            title={'Purchased'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex="created_at"
            title={'Created at'}
            render={(value) => (
              <TextField
                value={value ? dayjs(value).format('MMMM DD, YYYY HH:MM') : '-'}
              />
            )}
          />
        </Table>
      </List>

      <Separator />

      <List title={`${'Reviews'}`} pageHeaderProps={{ extra: <></> }}>
        <Table {...reviews} rowKey="id">
          <Table.Column
            dataIndex={'id'}
            title={'Id'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'title'}
            title={'Title'}
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex={'rating'}
            title={'Rating'}
            align="center"
            render={(value) => <TextField value={value || '0'} />}
          />
          <Table.Column
            dataIndex={'comment'}
            title={'Comment'}
            align="center"
            render={(value) => (
              <Tooltip title={value}>
                <TextField value={value || '-'} />
              </Tooltip>
            )}
          />

          <Table.Column
            dataIndex={'created_by_id'}
            title={'Created By'}
            render={(value) => (
              <TextField
                style={{ color: Colors.blue100, cursor: 'pointer' }}
                onClick={() => value && show('user-cruds', value)}
                value={`User#${value || '-'}`}
              />
            )}
          />
          <Table.Column
            dataIndex={'account_id'}
            title={'Reviewed Account'}
            render={(value) => (
              <Text
                style={{ color: Colors.blue100, cursor: 'pointer' }}
                onClick={() => value && show('provider-cruds', value)}
              >
                {`Provider#${value || '-'}`}
              </Text>
            )}
          />
          <Table.Column
            dataIndex="created_at"
            title={'Created at'}
            render={(value) => (
              <TextField
                value={value ? dayjs(value).format('MMMM DD, YYYY HH:MM') : '-'}
              />
            )}
          />
          <Table.Column
            dataIndex="is_report"
            title={'Report'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
        </Table>
      </List>
      <Separator />
      <List
        title={`${'Declined Providers'}`}
        pageHeaderProps={{ extra: <></> }}
      >
        <Table {...declinedOrders.tableProps} rowKey="id">
          <Table.Column
            dataIndex={'id'}
            title={'Id'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'provider_id'}
            title={'Provider'}
            render={(value) => (
              <TextField
                style={{ color: Colors.blue100, cursor: 'pointer' }}
                value={`Provider#${value}` || '-'}
                onClick={handleNavigate('provider-cruds', value)}
              />
            )}
          />

          <Table.Column
            dataIndex={['order', 'user_id']}
            title={'User'}
            align="center"
            render={(value) => (
              <TextField
                style={{ color: Colors.blue100, cursor: 'pointer' }}
                value={`User#${value}` || '-'}
                onClick={handleNavigate('user-cruds', value)}
              />
            )}
          />
          <Table.Column
            dataIndex={'is_deleted'}
            title={'Deleted'}
            render={(value) => <BooleanField value={value} />}
          />
        </Table>
      </List>
    </>
  );
};
