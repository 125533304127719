import * as React from 'react';
import {
  Edit,
  Form,
  Input,
  InputNumber,
  Switch,
  useForm,
} from '@pankod/refine-antd';
import { Reward } from '../../types';
export const EditReward = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<Reward>();
  const record = queryResult?.data?.data;
  return (
    <Edit saveButtonProps={saveButtonProps} title={'Edit Reward'}>
      <Form layout="vertical" {...formProps}>
        <Form.Item label="Title" name="title">
          <Input name="title" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input name="description" />
        </Form.Item>
        <Form.Item label="Points" name="points">
          <InputNumber name="points" />
        </Form.Item>
        <Form.Item label="Count" name="count">
          <InputNumber name="count" />
        </Form.Item>
        <Form.Item label="value" name="value">
          <InputNumber name="value" />
        </Form.Item>
        <Form.Item label="Valid For (Months)" name="valid_for_months">
          <InputNumber name="valid_for_months" />
        </Form.Item>
        <Form.Item label="Elite" name="is_elite">
          <Switch defaultChecked={record?.is_elite} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
