import * as React from 'react';
import { Create, Form, Input, useForm } from '@pankod/refine-antd';
import { Category } from '../../types';
export const CreateCategory = () => {
  const { formProps, saveButtonProps } = useForm<Category>();

  return (
    <Create saveButtonProps={saveButtonProps} title={'New Category'}>
      <Form layout="vertical" {...formProps}>
        <Form.Item label="Title" name="title">
          <Input name="title" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input name="description" />
        </Form.Item>
      </Form>
    </Create>
  );
};
