export const domain = (() => {
  return window.location.host === 'localhost:3000'
    ? 'https://api.gomint.dev.wtmsrv.com'
    : window.location.host.includes('staging')
    ? `https://api.gomint.staging.wtmsrv.com`
    : `https://api.gomint.app`;
})();

export const API_URL = `${domain}/api`;
export const UPLOAD_URI = `${API_URL}/upload-cruds/files`;
export const GET_UPLOAD = `${API_URL}/upload-cruds`;
export const API_KEY = '';
export const SOCKET_URI =
  window.location.host === 'localhost:3000'
    ? 'wss://ws.gomint.dev.wtmsrv.com/messaging'
    : window.location.host.includes('staging')
    ? 'wss://ws.gomint.staging.wtmsrv.com/messaging'
    : 'ws://api.gomint.app:6288/messaging';
