import React from 'react';
import {
  BooleanField,
  Grid,
  Show,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import dayjs from 'dayjs';
import { Settlement } from '../types';
import { ColumnWrapper, DetailsWrapper } from '../App';
import Colors from '../ Theme/ Colors';
import { useNavigation } from '@pankod/refine-core';
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const ShowSettlement = () => {
  const { show } = useNavigation();
  const screens = useBreakpoint();
  const { tableProps, tableQueryResult } = useTable<Settlement>({
    resource: `provider-settlement-cruds?&id=${+window.location.href.split(
      'show/'
    )[1]}`,
  });
  const record = tableProps?.dataSource && tableProps?.dataSource[0];
  return (
    <>
      <Show isLoading={tableQueryResult.isLoading}>
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>ID</Title>
            <Text>{record?.id || '-'}</Text>
            <Title level={5}>Provider</Title>
            <Text
              style={{ color: Colors.blue100, cursor: 'pointer' }}
              onClick={() =>
                record?.provider_id &&
                show('provider-cruds', record?.provider_id)
              }
            >
              {`Provider#${record?.provider_id || '-'}`}
            </Text>
            <Title level={5}>Order</Title>
            <Text
              style={{ color: Colors.blue100, cursor: 'pointer' }}
              onClick={() =>
                record?.order_id && show('order-cruds', record?.order_id)
              }
            >
              {`Order#${record?.order_id || '-'}`}
            </Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Gomint Earning</Title>
            <Text>
              {record?.gomint_earning
                ? Number(record?.gomint_earning)?.toFixed(2)
                : '-'}
            </Text>

            <Title level={5}>Provider Earning</Title>
            <Text>
              {record?.provider_earning
                ? Number(record?.provider_earning)?.toFixed(2)
                : '-'}
            </Text>
            <Title level={5}>Gomint Commission Percentage</Title>
            <Text>{record?.gomint_commission_percentage || '-'}</Text>

            <Title level={5}>Total Order Amount</Title>
            <Text>{record?.total_order_amount || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Is Deleted</Title>
            <BooleanField value={record?.is_deleted} />
            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>Updated At</Title>
            <Text>
              {record?.updated_at
                ? dayjs(record?.updated_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>
    </>
  );
};
