import React from 'react';
import {
  BooleanField,
  Grid,
  Show,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { DetailsWrapper, ColumnWrapper } from '../../App';
import { Admin } from '../../types';
import dayjs from 'dayjs';
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const ShowAdmin = () => {
  const screens = useBreakpoint();
  const { tableProps, tableQueryResult } = useTable<Admin>({
    resource: `admin-cruds?&id=${+window.location.href.split('show/')[1]}`,
  });
  const record = tableProps?.dataSource && tableProps?.dataSource[0];
  return (
    <>
      <Show isLoading={tableQueryResult.isLoading}>
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Name</Title>
            <Text>{record?.account.name || '-'}</Text>
            <Title level={5}>Account Type</Title>
            <Text>{record?.account.account_type || '-'}</Text>
            <Title level={5}>Cart Description</Title>
            <Text>{record?.role || '-'}</Text>
            <Title level={5}>Email</Title>
            <Text>{record?.account.email || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Gender</Title>
            <Text>{record?.account.gender || '-'}</Text>
            <Title level={5}>Phone</Title>
            <Text>{record?.account.phone || '-'}</Text>
            <Title level={5}>points</Title>
            <Text>{record?.account.points || '-'}</Text>
            <Title level={5}>Role</Title>
            <Text>{record?.role || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>points</Title>
            <Text>{record?.account.status || '-'}</Text>
            <Title level={5}>Email Confirmed</Title>
            <BooleanField value={record?.account.is_email_confirmed} />
            <Title level={5}> Phone Confirmed</Title>
            <BooleanField value={record?.account.is_phone_confirmed} />
            <Title level={5}> Is Deleted</Title>
            <BooleanField value={record?.is_deleted} />
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Address</Title>
            <Text>{record?.account.address || '-'}</Text>
            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>Updated At</Title>
            <Text>
              {record?.updated_at
                ? dayjs(record?.updated_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>
    </>
  );
};
