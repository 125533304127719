import React, { useState } from 'react';
import {
  BooleanField,
  CreateButton,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { EditButton, ShowButton } from '@pankod/refine-mui';
import { Admin } from '../../types';
import CustomModal from '../ CustomModal';
import useModal from '../../hooks/useModal';
import SearchModal from '../../Components/SearchModal';
import { Search } from '../../Components/ Search';
import { DeleteButtons } from '../../Components/DeleteButton';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import Colors from '../../ Theme/ Colors';
import DropDownFilter from '../../Components/DropDownFilter';

export const AdminListing: React.FC = () => {
  const [searchResults, setSearchResults] = useState<any>([]);
  const [modal, setModal] = useState<Admin>();
  const { closeModal, isVisible } = useModal();

  const { tableProps, tableQueryResult } = useTable<Admin>({
    syncWithLocation: true,
  });

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  }

  return (
    <List
      title={`${'Admin'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="admin-cruds"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            <CreateButton style={{ marginLeft: 10, marginRight: 5 }} />
            {/* <Button onClick={openModal}>Filter</Button> */}
          </div>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['account', 'name']}
          title={'Name'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="role"
          title={'Role'}
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Role'}
              options={[
                { label: 'Super Admin', value: 'super_admin' },
                { label: 'Admin', value: 'admin' },
              ]}
            />
          )}
          render={(value) => (
            <TextField
              value={
                value === 'super_admin'
                  ? 'Super Admin'
                  : value === 'admin'
                  ? 'Admin'
                  : '-'
              }
            />
          )}
        />

        <Table.Column
          dataIndex={['account', 'email']}
          title={'Email'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['account', 'phone']}
          title={'Phone'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['account', 'status']}
          title={'Status'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="is_deleted"
          title={'Deleted'}
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Status'}
              options={[
                { label: 'Active', value: 'false' },
                { label: 'Deleted', value: 'true' },
              ]}
            />
          )}
          render={(value) => <BooleanField value={value} />}
        />

        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          align="center"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <MdOutlineAdminPanelSettings
                  style={{ marginLeft: 5, marginTop: 3 }}
                  color={Colors.blue300}
                  className="icons"
                  size={20}
                  onClick={() => setModal(record)}
                />

                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.account_id}
                />
                <DeleteButtons
                  id={record?.account_id}
                  status={record?.account?.status}
                  path={'account-cruds'}
                  handleRefetch={tableQueryResult.refetch}
                />
              </Space>
            );
          }}
        />
      </Table>
      <SearchModal visible={isVisible} closeModal={closeModal} type="admin" />
      {modal && (
        <CustomModal
          visible={modal}
          setVisible={setModal}
          refresh={tableQueryResult.refetch}
        />
      )}
    </List>
  );
};
