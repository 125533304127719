import * as React from 'react';
import {
  Edit,
  Form,
  Input,
  InputNumber,
  Select,
  useForm,
} from '@pankod/refine-antd';
import { Tier } from '../../types';
export const EditTier = () => {
  const { formProps, saveButtonProps } = useForm<Tier>();

  return (
    <Edit saveButtonProps={saveButtonProps} title={'New Tier'}>
      <Form layout="vertical" {...formProps}>
        <Form.Item label="Title" name="title">
          <Input name="title" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input name="description" />
        </Form.Item>
        <Form.Item label="Points Threshold" name="points_threshold">
          <InputNumber name="points_threshold" />
        </Form.Item>
        <Form.Item label="Extra Ratio" name="extra_ratio">
          <InputNumber name="extra_ratio" />
        </Form.Item>
        <Form.Item label="Tier" name="tier">
          <Select
            options={[
              { label: 'basic', value: 'basic' },
              { label: 'elite', value: 'elite' },
            ]}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
