import React from 'react';
import logo from '.././../../ images/logo.png';
import {
  Typography,
  AntdLayout,
  Row,
  Col,
  Card,
  Input,
  Button,
  Form,
} from '@pankod/refine-antd';

import styled from 'styled-components';
import { ResetPasswordAction } from '../../../Actions/AuthActions';
import colors from '../../../ Theme/ Colors';
import { useNavigation } from '@pankod/refine-core';
const { Title } = Typography;
export const ResetPassword: React.FC = () => {
  const { push } = useNavigation();
  const navigateToLogin = () => {
    push('/login');
  };
  const CardTitle = (
    <Title level={3} className="layout-title">
      {'Reset Password'}
    </Title>
  );
  return (
    <AntdLayout className="antdLayout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Image src={logo} width="80" alt="logo" />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={colors.primaryLight}
                onFinish={(val) => {
                  ResetPasswordAction(val?.code, navigateToLogin);
                }}
                requiredMark={false}
              >
                <Form.Item
                  name="code"
                  label={'  New Password'}
                  rules={[
                    {
                      validator: async (_, password) => {
                        if (password.length < 8) {
                          return Promise.reject(
                            new Error(
                              ' Password must be more than 8 characters'
                            )
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input type="password" size="large" placeholder="●●●●●" />
                </Form.Item>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={colors.primaryLight}
                  style={{
                    background: 'linear-gradient( #4BC1FA 40% , #4EB0FD 50% )',
                  }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: colors.primaryLight,
                    }}
                  >
                    {'send'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
export const Image = styled.img`
  margin-bottom: 26px;
  align-self: center;
`;
