import React from 'react';
import { Form, InputNumber, Modal } from '@pankod/refine-antd';
import { Create } from '@pankod/refine-mui';
import { useFormik } from 'formik';
import { CreateRecordAction } from '../../Actions/ConfigsActions';

type Props = {
  visible: boolean;
  provider_id: number;
  close: () => void;
};

type Inputs = {
  provider_id: number;
  amount: number;
};

export const CreateProviderSettlementModal = (props: Props) => {
  const { handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      amount: 0,
      due_to_provider: 0,
      total_provider_earning: 0,
    },

    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        provider_id: props.provider_id,
        amount: submittedValues.amount,
      };
      if (Object.keys(inputs)?.length > 0) {
        CreateRecordAction('settlement-payment-cruds', inputs, props.close);
      }
    },
  });

  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };

  return (
    <Modal visible={props.visible} footer={null} onCancel={props.close}>
      <Create saveButtonProps={buttonProps} title={'New Settlement'}>
        <Form layout="vertical">
          <Form.Item label="Amount" name={'amount'}>
            <InputNumber
              name="amount"
              onChange={(e) => setFieldValue('amount', e)}
            />
          </Form.Item>
        </Form>
      </Create>
    </Modal>
  );
};
