import React from 'react';
import {
  CreateButton,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { EditButton, ShowButton } from '@pankod/refine-mui';
import { Category } from '../../types';
import { Search } from '../../Components/ Search';
import SearchModal from '../../Components/SearchModal';
import useModal from '../../hooks/useModal';
export const CategoryListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const { closeModal, isVisible } = useModal();
  const { tableProps } = useTable<Category>({
    syncWithLocation: true,
  });
  //   const handleExportList = () => {
  //     ExportList(tableProps.dataSource || [], 'UniverseMessages');
  //   };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  }
  return (
    <List
      title={`${'Category'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="category-cruds"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            <CreateButton style={{ marginLeft: 10, marginRight: 5 }} />
            {/* <Button onClick={openModal}>Filter</Button> */}
          </div>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'title'}
          title={'Title'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'description'}
          title={'Description'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          align="center"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
                {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
              </Space>
            );
          }}
        />
      </Table>
      <SearchModal
        visible={isVisible}
        closeModal={closeModal}
        type="category"
      />
    </List>
  );
};
