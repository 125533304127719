import { Input, Tooltip } from '@pankod/refine-antd';
import React, { useState } from 'react';
import { RiFilterOffLine } from 'react-icons/ri';
import { SearchAction } from '../../Actions/ConfigsActions';

interface Props {
  path: string;
  setSearchResults: (val: any) => void;
  searchResults: any[];
  type?: number;
  lawyer?: boolean;
  account_type?: string;
}
export const Search = ({
  path,
  setSearchResults,
  searchResults,
  account_type,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const handleSearch = (value: string) => {
    SearchAction(
      `${path}/search/?search=${value}&account_type=${account_type}`,
      // search/?account_type=provider&search=anas
      setSearchResults,
      setLoading
    );
  };
  return (
    <>
      <Input.Group compact>
        <Input.Search
          placeholder={'Search...'}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={(value) =>
            value ? handleSearch(value) : setSearchResults([])
          }
          allowClear
          style={{ width: 250 }}
          loading={loading}
        />
      </Input.Group>
      {searchResults?.length > 0 && (
        <Tooltip
          placement="top"
          title={<p style={{ marginBottom: -1 }}>{'Cancel Search'}</p>}
          style={{ maxWidth: 20 }}
        >
          <RiFilterOffLine
            color="#f40c0c"
            size={30}
            onClick={() => {
              setSearchResults([]);
              setSearchValue('');
            }}
            className="icons"
          />
        </Tooltip>
      )}
    </>
  );
};
