import React, { useState } from 'react';
import logo from '.././../../ images/logo.png';

import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from '@pankod/refine-antd';

import styled from 'styled-components';
import colors from '../../../ Theme/ Colors';
import { ForgotPasswordAction } from '../../../Actions/AuthActions';
import { useNavigation } from '@pankod/refine-core';
const { Title } = Typography;
export const ForgotPassword: React.FC = () => {
  const [Disabled, setDisabled] = useState(false);
  const { push } = useNavigation();
  const navigateToVerification = (email: string) => {
    push('/verification');

    return email;
  };
  const CardTitle = (
    <Title level={3} className="layout-title">
      {'Forgot Password '}
    </Title>
  );
  const handleDisableButton = (value: boolean) => {
    setDisabled(value);
  };
  return (
    <AntdLayout className="antdLayout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Image src={logo} width="180" alt="logo" />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={colors.primaryLight}
                onFinish={(values) => {
                  ForgotPasswordAction(
                    values,
                    navigateToVerification,
                    handleDisableButton
                  );
                }}
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label={' Email'}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="sample@smaple.com" />
                </Form.Item>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={colors.primaryLight}
                  style={{
                    background: 'linear-gradient( #4BC1FA 40% , #4EB0FD 50% )',
                  }}
                  disabled={Disabled}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: colors.primaryLight,
                    }}
                  >
                    {'Send'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
export const Image = styled.img`
  margin-bottom: 26px;
  align-self: center;
`;
