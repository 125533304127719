import Swal from 'sweetalert2';
import {
  ApproveRequestDeletionAction,
  EditRecordAction,
} from '../Actions/ConfigsActions';

export const handleDeleteRecord = (
  id: number,
  type: string,
  inputs: any,
  refresh: () => void
) => {
  Swal.fire({
    title: 'Are you sure?',
    showCancelButton: true,
    confirmButtonColor: '#db2828',
    confirmButtonText: 'Accept',
    cancelButtonText: 'Cancel',
  }).then(async (result) => {
    if (result.isConfirmed) {
      EditRecordAction(type, id, inputs, refresh);
    }
  });
};

export const handleRestoreRecord = (
  id: number,
  type: string,
  inputs: any,
  refresh: () => void
) => {
  Swal.fire({
    title: 'Are you sure?',
    showCancelButton: true,
    confirmButtonColor: '#db2828',
    confirmButtonText: 'Accept',
    cancelButtonText: 'Cancel',
  }).then(async (result) => {
    if (result.isConfirmed) {
      EditRecordAction(type, id, inputs, refresh);
    }
  });
};

export const handleRequestDeleteRecord = (id: number, refresh: () => void) => {
  Swal.fire({
    title: 'Are you sure?',
    showCancelButton: true,
    confirmButtonColor: '#db2828',
    confirmButtonText: 'Accept',
    cancelButtonText: 'Cancel',
  }).then(async (result) => {
    if (result.isConfirmed) {
      ApproveRequestDeletionAction({ account_id: id }, refresh);
    }
  });
};
