import * as React from 'react';
import {
  Edit,
  Form,
  Input,
  InputNumber,
  Select,
  useTable,
} from '@pankod/refine-antd';
import { AccountStatusArray, IAccount } from '../../types';
import { useParams } from 'react-router-dom';
import { EditRecordAction } from '../../Actions/ConfigsActions';
import { useFormik } from 'formik';

type Inputs = {
  phone?: number;
  name?: string;
  email?: string;
  points?: number;
  status?: string;
};
export const EditUser = () => {
  const params = useParams();
  const { tableProps, tableQueryResult } = useTable<IAccount>({
    resource: `account-cruds?id=${params.id}`,
  });

  const record = tableProps?.dataSource && tableProps?.dataSource[0];

  const { values, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: {
      name: record?.name,
      email: record?.email,
      phone: record?.phone,
      points: record?.points,
      status: record?.status,
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      if (submittedValues.name !== record?.name) {
        inputs.name = submittedValues.name;
      }
      if (submittedValues.email !== record?.email) {
        inputs.email = submittedValues.email;
      }
      if (submittedValues.phone !== record?.phone) {
        inputs.phone = submittedValues.phone;
      }
      if (submittedValues.points !== record?.points) {
        inputs.points = Number(submittedValues.points);
      }
      if (submittedValues.status !== record?.status) {
        inputs.status = submittedValues.status;
      }

      if (Object.keys(inputs)?.length > 0) {
        EditRecordAction(
          'account-cruds',
          params.id,
          inputs,
          tableQueryResult?.refetch
        );
      }
    },
  });

  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };

  React.useEffect(() => {
    if (record) {
      setFieldValue('name', record?.name);
      setFieldValue('email', record?.email);
      setFieldValue('phone', record?.phone);
      setFieldValue('points', record?.points);
      setFieldValue('status', record?.status);
    }
  }, [record, setFieldValue]);
  return (
    <Edit saveButtonProps={buttonProps} title={'Edit User'}>
      <Form layout="vertical">
        <Form.Item label="Name">
          <Input name="name" onChange={handleChange} value={values?.name} />
        </Form.Item>
        <Form.Item label="Email">
          <Input name="email" onChange={handleChange} value={values.email} />
        </Form.Item>
        <Form.Item label="Phone">
          <Input name="phone" onChange={handleChange} value={values.phone} />
        </Form.Item>
        <Form.Item label="Status">
          <Select
            options={AccountStatusArray}
            value={values.status}
            onChange={(val) => setFieldValue('status', val)}
          />
        </Form.Item>
        <Form.Item label="Points">
          <InputNumber
            name="points"
            onChange={(value) => setFieldValue('points', value)}
            value={values.points}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
