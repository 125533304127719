import React from 'react';
import { List, Space, Table, TextField, useTable } from '@pankod/refine-antd';
import { EditButton, ShowButton } from '@pankod/refine-mui';
import { Order } from '../../types';
import { Search } from '../../Components/ Search';
import SearchModal from '../../Components/SearchModal';
import useModal from '../../hooks/useModal';
import DropDownFilter from '../../Components/DropDownFilter';
import { useNavigation } from '@pankod/refine-core';
import colors from '../../ Theme/ Colors';
export const OrderListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const { show } = useNavigation();
  const { closeModal, isVisible } = useModal();
  const { tableProps } = useTable<Order>({
    syncWithLocation: true,
  });

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  }

  return (
    <List
      title={`${'Order'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="order-cruds"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            {/* <Button onClick={openModal}>Filter</Button> */}
          </div>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="user_id"
          title={'User'}
          align="center"
          render={(value) => (
            <TextField
              value={value ? `User#${value}` : '-'}
              style={{ cursor: 'pointer', color: colors.blue100 }}
              onClick={() => show('user-cruds', value)}
            />
          )}
        />
        <Table.Column
          dataIndex="provider_id"
          title={'Provider'}
          align="center"
          render={(value) => (
            <TextField
              value={value ? `Provider#${value}` : '-'}
              style={{ cursor: 'pointer', color: colors.blue100 }}
              onClick={() => show('provider-cruds', value)}
            />
          )}
        />
        <Table.Column
          dataIndex="status"
          title={'Status'}
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Status'}
              options={[
                { label: 'Pending', value: 'pending' },
                { label: 'Accepted', value: 'accepted' },
                { label: 'Started', value: 'started' },
                { label: 'Completed', value: 'completed' },
                { label: 'Canceled', value: 'canceled' },
                { label: 'Failed to dispatch', value: 'failed_to_dispatch' },
                { label: 'Scheduled', value: 'scheduled' },
              ]}
            />
          )}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="order_type"
          title={'Order Type'}
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Type'}
              options={[
                { label: 'Delivery', value: 'delivery' },
                { label: 'Shop', value: 'shop' },
              ]}
            />
          )}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="provider_type"
          title={'Provider Type'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="payment_method"
          title={'Payment Method'}
          align={'center'}
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Payment Method'}
              options={[
                { label: 'Cash on delivery', value: 'cod' },
                { label: 'Card', value: 'card' },
              ]}
            />
          )}
          render={(value) => (
            <TextField
              value={
                value === 'cod'
                  ? 'Cash on delivery'
                  : value === 'card'
                  ? 'Card'
                  : '-'
              }
            />
          )}
        />
        <Table.Column
          dataIndex="cost_estimation"
          title={'Cost Estimation'}
          align={'center'}
          render={(value) => (
            <TextField value={value ? Number(value)?.toFixed(2) : '-'} />
          )}
        />
        <Table.Column
          dataIndex={['rewarded_points']}
          title={'Rewarded Points'}
          align={'center'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex="time_estimation"
          title={'Time Estimation'}
          render={(value) => <TextField value={`${value} mins` || '-'} />}
        />
        <Table.Column
          dataIndex="total_distance"
          title={'Total Distance'}
          render={(value) => (
            <TextField value={value ? `${Number(value)?.toFixed(2)} m` : '-'} />
          )}
        />

        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
                {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
              </Space>
            );
          }}
        />
      </Table>
      <SearchModal visible={isVisible} closeModal={closeModal} type="order" />
    </List>
  );
};
