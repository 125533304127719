import React, { useState } from 'react';
import { Form, Modal } from '@pankod/refine-antd';
import { CustomInput } from '../CustomInput';

interface Props {
  visible: any;
  openModal?: () => void;
  closeModal: () => void;
  type?: string;
}
const SearchModal = ({ visible, closeModal, type }: Props) => {
  const [text, setText] = useState<{
    status?: string;
  }>({});

  const onChange = (name: string) => {
    return (e: any) => {
      setText({ ...text, [name]: e.target.value });
    };
  };

  const handleSubmit = () => {
    closeModal();
  };

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      width={700}
    >
      <div style={{ marginTop: '20px' }}>
        <Form layout="vertical">
          {type === 'admin' && (
            <CustomInput
              label={'Status'}
              name={'Status'}
              onChange={onChange('status')}
            />
          )}
        </Form>
      </div>
    </Modal>
  );
};
export default SearchModal;
