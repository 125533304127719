import * as React from 'react';
import { Edit, Form, Input, useForm } from '@pankod/refine-antd';
import { Order } from '../../types';
export const EditOrder = () => {
  const { formProps, saveButtonProps } = useForm<Order>();

  return (
    <Edit saveButtonProps={saveButtonProps} title={'Edit Order'}>
      <Form layout="vertical" {...formProps}>
        <Form.Item label="Contact Person" name="contact_person">
          <Input name="contact_person" />
        </Form.Item>
        <Form.Item label="Contact Number" name="contact_number">
          <Input name="contact_number" />
        </Form.Item>
        <Form.Item label="Provider Notes" name="provider_notes">
          <Input name="provider_notes" />
        </Form.Item>
      </Form>
    </Edit>
  );
};
