import React from 'react';
import {
  BooleanField,
  Grid,
  List,
  Show,
  Table,
  TextField,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { useNavigation, useShow } from '@pankod/refine-core';
import dayjs from 'dayjs';
import { DetailsWrapper, ColumnWrapper, Separator } from '../../App';
import { Reward } from '../../types';
import colors from '../../ Theme/ Colors';
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const ShowReward = () => {
  const screens = useBreakpoint();
  const { show } = useNavigation();
  const { queryResult } = useShow<Reward>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const Activereward = useTable({
    resource: 'active-reward-cruds',
  });
  const Rewardcategory = useTable({
    resource: 'reward-category-cruds',
  });
  return (
    <>
      <Show isLoading={isLoading}>
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>ID</Title>
            <Text>{record?.id || '-'}</Text>
            <Title level={5}>Title</Title>
            <Text>{record?.title || '-'}</Text>
            <Title level={5}>Description</Title>
            <Text>{record?.description || '-'}</Text>
            <Title level={5}>Value</Title>
            <Text>{record?.value || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Valid For</Title>
            <Text>{`${record?.valid_for_months} months` || '-'}</Text>
            <Title level={5}>Points</Title>
            <Text>{record?.points || '-'}</Text>
            <Title level={5}>Count</Title>
            <Text>{record?.count || '-'}</Text>
            <Title level={5}>Elite</Title>
            <BooleanField value={record?.is_elite} />
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Deleted</Title>
            <BooleanField value={record?.is_deleted} />
            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>
      <div style={{ marginTop: 20 }} />
      <Separator />
      <List title={`${'Active reward'}`} pageHeaderProps={{ extra: <></> }}>
        <Table {...Activereward.tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title={'Id'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={['account', 'id']}
            title={'User'}
            render={(value, rec: any) => (
              <TextField
                value={rec?.account?.name || '-'}
                style={{ cursor: 'pointer', color: colors.blue100 }}
                onClick={() =>
                  show(`${rec?.account?.account_type}-cruds`, rec?.account?.id)
                }
              />
            )}
          />

          <Table.Column
            dataIndex="used"
            title={'Used'}
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex="is_delete"
            title={'Is Delete'}
            render={(value) => <BooleanField value={value} />}
          />
        </Table>
      </List>
      <Separator />

      <List title={`${'Category Reward'}`} pageHeaderProps={{ extra: <></> }}>
        <Table {...Rewardcategory.tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title={'Id'}
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex={'category_id'}
            title={'Category'}
            render={(value) => (
              <TextField
                style={{ color: colors.blue100, cursor: 'pointer' }}
                value={`Category#${value}` || '-'}
                onClick={() => show('category-cruds', value)}
              />
            )}
          />
        </Table>
      </List>
    </>
  );
};
