import React from 'react';
import {
  CreateButton,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { EditButton, ShowButton } from '@pankod/refine-mui';
import { Tier } from '../../types';
import { Search } from '../../Components/ Search';
import SearchModal from '../../Components/SearchModal';
import useModal from '../../hooks/useModal';
import DropDownFilter from '../../Components/DropDownFilter';
export const TierListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const { closeModal, isVisible } = useModal();
  const { tableProps } = useTable<Tier>({
    syncWithLocation: true,
  });
  //   const handleExportList = () => {
  //     ExportList(tableProps.dataSource || [], 'UniverseMessages');
  //   };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  }

  return (
    <List
      title={`${'Tier'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="tier-cruds"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            <CreateButton style={{ marginLeft: 10, marginRight: 5 }} />
            {/* <Button onClick={openModal}>Filter</Button> */}
          </div>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'title'}
          title={'Title'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex="tier"
          title={'Tier'}
          align="center"
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Tier'}
              options={[
                { label: 'Basic', value: 'basic' },
                { label: 'Elite', value: 'elite' },
              ]}
            />
          )}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex="points_threshold"
          title={'Points threshold'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex="extra_ratio"
          title={'Extra Ratio'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
                {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
              </Space>
            );
          }}
        />
      </Table>
      <SearchModal visible={isVisible} closeModal={closeModal} type="tier" />
    </List>
  );
};
