import React from 'react';
import {
  BooleanField,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { EditButton } from '@pankod/refine-mui';
import { Systemconfig } from '../types';

export const SystemConfigList: React.FC = () => {
  const [searchResults] = React.useState<any>([]);
  const { tableProps } = useTable<Systemconfig>({
    syncWithLocation: true,
  });
  //   const handleExportList = () => {
  //     ExportList(tableProps.dataSource || [], 'Systemconfig');
  //   };
  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  }
  return (
    <List title={`${'System Config'}`}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'base_fare'}
          title={'Base Fare'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'driver_delivery_min_fare'}
          title={'Driver Delivery Min Fare'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'elite_threshold'}
          title={'Elite Threshold'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'fee_per_item'}
          title={'Fee Per Item'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'fee_per_kilometer'}
          title={'Fee Per Kilometer'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'fee_per_min_delivery'}
          title={'Fee Per Min Delivery'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'fee_per_min_shop'}
          title={'Fee Per Min Shop'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'fee_per_stop'}
          title={'Fee Per Stop'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'first_stop_fee'}
          title={'First Stop Fee'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'gomint_order_percentage'}
          title={'Gomint Order Percentage'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'max_extra_fees'}
          title={'Max Extra Fees'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'order_acceptance_buffer'}
          title={'Order Acceptance Buffer'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'points_per_jod'}
          title={'Points Per Jod'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'base_percentage'}
          title={'Base Percentage'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'base_threshold'}
          title={'Base Threshold'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'dispatching_timeout'}
          title={'Dispatching Timeout'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'max_order_value'}
          title={'Max Order Value'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'order_schedule_buffer'}
          title={'Order Schedule Buffer'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'percentage_1'}
          title={'Percentage 1'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'percentage_2'}
          title={'Percentage 2'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'percentage_3'}
          title={'Percentage 3'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'threshold_1'}
          title={'Threshold 1'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'threshold_2'}
          title={'Threshold 2'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'threshold_3'}
          title={'Threshold 3'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={'support_phone'}
          title={'Support Phone'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'is_active'}
          title={'Is Active'}
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          align="center"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
