import React from 'react';
import { Grid, Show, Typography } from '@pankod/refine-antd';
import { useShow } from '@pankod/refine-core';
import dayjs from 'dayjs';
import { ColumnWrapper, DetailsWrapper } from '../../App';
import { Tier } from '../../types';
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const ShowTier = () => {
  const screens = useBreakpoint();
  const { queryResult } = useShow<Tier>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <>
      <Show isLoading={isLoading}>
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>ID</Title>
            <Text>{record?.id || '-'}</Text>
            <Title level={5}>Tier</Title>
            <Text>{record?.tier || '-'}</Text>
            <Title level={5}>Title</Title>
            <Text>{record?.title || '-'}</Text>
            <Title level={5}>Description</Title>
            <Text>{record?.description || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Points Threshold</Title>
            <Text>{record?.points_threshold || '-'}</Text>
            <Title level={5}>Extra Ratio</Title>
            <Text>{record?.extra_ratio || '-'}</Text>
            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>Updated At</Title>
            <Text>
              {record?.updated_at
                ? dayjs(record?.updated_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>
    </>
  );
};
