import React, { useEffect } from 'react';
import {
  BooleanField,
  Button,
  EditButton,
  FilterDropdown,
  Grid,
  List,
  ListButton,
  Show,
  ShowButton,
  Space,
  Table,
  TableProps,
  TextField,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { ColumnWrapper, DetailsWrapper, Separator } from '../../App';
import { DocumentStatus, IAccount, Order } from '../../types';
import dayjs from 'dayjs';
import { GET_UPLOAD } from '../../configs';
import styled from 'styled-components';
import { CreateButton, Tooltip } from '@pankod/refine-mui';
import { BiCheck } from 'react-icons/bi';
import { RiCloseLine } from 'react-icons/ri';
import {
  ApproveDocumentAction,
  GetOneRecordAction,
  RejectDocumentAction,
  UnoccupyDriverAction,
} from '../../Actions/ConfigsActions';
import Swal from 'sweetalert2';
import { useNavigation } from '@pankod/refine-core';
import { DeleteButtons } from '../../Components/DeleteButton';
import colors from '../../ Theme/ Colors';
import { handleRequestDeleteRecord } from '../../ Utils/HelperFunctions';
import { BsTrash } from 'react-icons/bs';
import { MdOutlineDoNotTouch } from 'react-icons/md';
import { useModalForm } from '@pankod/refine-react-hook-form';
import { CreateProviderSettlementModal } from '../CreateProviderSettlementModal';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const ShowProvider = () => {
  const screens = useBreakpoint();
  const { show } = useNavigation();
  const id = +window.location.href.split('show/')[1];
  const [providerDocs, setProviderDocs] = React.useState<TableProps<any>>();
  const [dueAmount, setDueAmount] = React.useState(0);

  const provider = useTable<IAccount>({
    resource: `provider-settlement-cruds?&provider_id=${id}`,
  });
  const { tableProps, tableQueryResult } = useTable<IAccount>({
    resource: `account-cruds?account_type=provider&id=${id}`,
  });

  const orders = useTable<Order[]>({
    resource: `order-cruds?provider_id=${id}`,
  });

  const reviews = useTable<Order[]>({
    resource: `review-cruds?account_id=${id}`,
  });

  const declinedOrders = useTable({
    resource: `providers-declined-cruds?provider_id=${id}`,
  });

  const settlementPayments = useTable({
    resource: `settlement-payment-cruds?provider_id=${id}`,
  });

  const {
    modal: { visible, close, show: showModal },
  } = useModalForm<any>();

  const handleNavigate = (name: string, id: number) => () => {
    show(name, id);
  };

  const record = tableProps?.dataSource && tableProps?.dataSource[0];

  const handleRefetch = () => {
    provider.tableQueryResult.refetch();
    settlementPayments.tableQueryResult.refetch();
    tableQueryResult.refetch();
    id &&
      GetOneRecordAction('settlement-payment-cruds/due-amount', id, (val) =>
        setDueAmount(val)
      );
  };

  const handleRejectDocument = (id: number) => {
    Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#db2828',
      confirmButtonText: 'Accept',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        RejectDocumentAction(id, handleRefetch);
      }
    });
  };

  const handleApproveDocument = (id: number, account_id: number) => {
    Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#db2828',
      confirmButtonText: 'Accept',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        ApproveDocumentAction(id, account_id, handleRefetch);
      }
    });
  };

  const closeModal = () => {
    handleRefetch();
    close();
  };

  useEffect(() => {
    if (record) {
      setProviderDocs({
        ...tableProps,
        dataSource: record?.providerDocuments || [],
      });
      id &&
        GetOneRecordAction('settlement-payment-cruds/due-amount', id, (val) =>
          setDueAmount(val)
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);
  return (
    <>
      <CreateProviderSettlementModal
        visible={visible}
        close={closeModal}
        provider_id={id}
      />
      <Show
        isLoading={tableQueryResult.isLoading}
        pageHeaderProps={{
          extra: (
            <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
              <ListButton />
              <EditButton />
              <Button
                icon={
                  <MdOutlineDoNotTouch
                    style={{ marginBottom: -2, marginRight: 5 }}
                  />
                }
                onClick={() =>
                  UnoccupyDriverAction(
                    record?.id || 0,
                    tableQueryResult?.refetch
                  )
                }
              >
                Unoccupy Driver
              </Button>
              {record?.status === 'active' && (
                <Button
                  icon={
                    <BsTrash style={{ marginBottom: -2, marginRight: 5 }} />
                  }
                  onClick={() =>
                    handleRequestDeleteRecord(
                      record?.id || 0,
                      tableQueryResult?.refetch
                    )
                  }
                >
                  Delete Account
                </Button>
              )}
              <ShowButton />
            </Space>
          ),
        }}
      >
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>ID</Title>
            <Text>{record?.id || '-'}</Text>
            <Title level={5}>Name</Title>
            <Text>{record?.name || '-'}</Text>
            <Title level={5}>Gender</Title>
            <Text>{record?.gender || '-'}</Text>
            <Title level={5}>Phone</Title>
            <Text>{record?.phone || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Account Type</Title>
            <Text>{record?.account_type || '-'}</Text>

            <Title level={5}>Average Rate</Title>
            <Text>{record?.average_rate || '-'}</Text>
            <Title level={5}>Status</Title>
            <Text>{record?.status || '-'}</Text>

            <Title level={5}>Due Amount</Title>
            <Text
              style={{
                fontWeight: 'bold',
                color:
                  dueAmount < 0 ? '#1da57a' : dueAmount > 0 ? 'red' : 'black',
              }}
            >
              {(dueAmount || 0)?.toFixed(2)}
            </Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Provider type</Title>
            <Text>{record?.provider?.provider_type || '-'}</Text>
            <Title level={5}>Current type</Title>
            <Text>{record?.provider?.current_type || '-'}</Text>

            <Title level={5}>Email Confirmed</Title>
            <BooleanField value={record?.is_email_confirmed} />
            <Title level={5}> Phone Confirmed</Title>
            <BooleanField value={record?.is_phone_confirmed} />
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Dob</Title>
            <Text>
              {record?.dob
                ? dayjs(record?.dob).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>Updated At</Title>
            <Text>
              {record?.updated_at
                ? dayjs(record?.updated_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY')
                : '-'}
            </Text>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>
      <Separator />
      <List
        title={`${'Provider Documents'}`}
        pageHeaderProps={{ extra: <></> }}
      >
        <Table {...providerDocs} rowKey="id">
          <Table.Column
            dataIndex={'id'}
            title={'ID'}
            render={(value) => <TextField value={value || '-'} />}
          />
          {/* <Table.Column
            dataIndex={'provider_document_type'}
            title={'Document Type'}
            render={(value) => <TextField value={value || '-'} />}
          /> */}
          <Table.Column
            dataIndex="status"
            title={'Status'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="upload_id"
            title={'Documents'}
            align="center"
            render={(value) => (
              <a
                href={`${GET_UPLOAD}/${value}`}
                target="_blank"
                rel="noreferrer"
                className="icons"
                style={{ color: colors.blue100 }}
              >
                View Document
              </a>
            )}
          />
          <Table.Column
            dataIndex="created_at"
            title={'Created At'}
            align="center"
            render={(value) => (
              <TextField value={dayjs(value).format('MMMM DD, YYYY') || '-'} />
            )}
          />
          <Table.Column
            dataIndex="is_deleted"
            title={'Deleted'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column<any>
            title={'Actions'}
            dataIndex="actions"
            fixed="right"
            align="center"
            key="actions"
            render={(_text, doc): any => {
              return (
                <Space>
                  {doc.status !== DocumentStatus.rejected && (
                    <Tooltip title="Reject">
                      <Button
                        onClick={() => handleRejectDocument(doc?.id)}
                        style={{
                          borderRadius: 5,
                          border: '1px red solid',
                          width: 50,
                          height: 30,
                        }}
                      >
                        <RiCloseLine size={20} color={'red'} />
                      </Button>
                    </Tooltip>
                  )}
                  {doc.status !== DocumentStatus.approved && (
                    <Tooltip title="Approve">
                      <Button
                        onClick={() =>
                          handleApproveDocument(doc?.id, doc?.account_id)
                        }
                        style={{
                          borderRadius: 5,
                          border: '1px #16db16 solid',
                          width: 50,
                          height: 30,
                        }}
                      >
                        <BiCheck size={20} color={'#16db16'} />
                      </Button>
                    </Tooltip>
                  )}
                  <DeleteButtons
                    id={doc.id}
                    is_deleted={doc.is_deleted}
                    path={'provider-documents-cruds'}
                    handleRefetch={handleRefetch}
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>

      <Separator />

      <List
        title={`${'Orders'}`}
        pageHeaderProps={{
          extra: <div style={{ display: 'flex', flexDirection: 'row' }}></div>,
        }}
      >
        <Table {...orders?.tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title={'ID'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={['user_id']}
            title={'User'}
            render={(value) => (
              <TextField
                value={`User#${value}`}
                style={{ color: colors.blue100, cursor: 'pointer' }}
                onClick={handleNavigate('user-cruds', value)}
              />
            )}
          />
          {/* <Table.Column
            dataIndex="actual_cost"
            title={'Actual Cost'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'actual_time'}
            title={'Actual Time'}
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex="contact_number"
            title={'Contact Number'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="contact_person"
            title={'Contact Person'}
            render={(value) => <TextField value={value || '-'} />}
          /> */}
          <Table.Column
            dataIndex="status"
            title={'Status'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="cost_estimation"
            title={'Cost Estimation'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="order_type"
            title={'Order Type'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="provider_type"
            title={'Provider Type'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="payment_method"
            title={'Payment Method'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={['rewarded_points']}
            title={'Rewarded Points'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="shopping_cost_estimation"
            title={'Shopping Cost Estimation'}
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex="time_estimation"
            title={'Time Estimation'}
            render={(value) => <TextField value={`${value} mins` || '-'} />}
          />
          <Table.Column
            dataIndex="total_distance"
            title={'Total Distance'}
            render={(value) => <TextField value={`${value} m` || '-'} />}
          />

          <Table.Column<any>
            title={'Actions'}
            dataIndex="actions"
            fixed="right"
            key="actions"
            render={(_text, record): any => {
              return (
                <ShowButton
                  hideText
                  size="small"
                  onClick={handleNavigate('order-cruds', record.id)}
                />
              );
            }}
          />
        </Table>
      </List>
      <Separator />
      <List
        title={`${'Settlement Payments'}`}
        pageHeaderProps={{
          extra: <CreateButton onClick={() => showModal()} />,
        }}
      >
        <Table {...settlementPayments.tableProps} rowKey="id">
          <Table.Column
            dataIndex={'amount'}
            title={'Amount'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="created_at"
            title={'Created At'}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <RangePicker />
              </FilterDropdown>
            )}
            render={(value) => (
              <TextField value={dayjs(value).format('MMMM DD, YYYY')} />
            )}
          />
        </Table>
      </List>

      <Separator />
      <List
        title={`${'Provider Settlement'}`}
        pageHeaderProps={{ extra: <></> }}
      >
        <Table {...provider.tableProps} rowKey="id">
          <Table.Column
            dataIndex={'id'}
            title={'Id'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'provider_earning'}
            title={'Provider Earning'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="gomint_earning"
            title={'Gomint Earning'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="order_id"
            title={'Order Id'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex="created_at"
            title={'Created At'}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <RangePicker />
              </FilterDropdown>
            )}
            render={(value) => (
              <TextField value={dayjs(value).format('MMMM DD, YYYY')} />
            )}
          />
        </Table>
      </List>

      <Separator />

      <List
        title={`${'Reviews'}`}
        pageHeaderProps={{
          extra: <div style={{ display: 'flex', flexDirection: 'row' }}></div>,
        }}
      >
        <Table {...reviews.tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title={'ID'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={['created_by', 'name']}
            title={"Reporter's name"}
            render={(value, rec) => (
              <TextField
                value={value}
                style={{ cursor: 'pointer', color: '#1890ff' }}
                // @ts-ignore
                onClick={handleNavigate('user-cruds', rec?.created_by?.id)}
              />
            )}
          />
          <Table.Column
            dataIndex={['account', 'name']}
            title={"Defendant's name"}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'title'}
            title={'Title'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'comment'}
            title={'comment'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'is_report'}
            title={'Is Report'}
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column<any>
            title={'Actions'}
            dataIndex="actions"
            fixed="right"
            align="center"
            key="actions"
            render={(_text, record): any => {
              return (
                <ShowButton
                  hideText
                  size="small"
                  onClick={handleNavigate('review-cruds', record.id)}
                />
              );
            }}
          />
        </Table>
      </List>

      <Separator />
      <List title={`${'Declined Orders'}`} pageHeaderProps={{ extra: <></> }}>
        <Table {...declinedOrders.tableProps} rowKey="id">
          <Table.Column
            dataIndex={'id'}
            title={'Id'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'order_id'}
            title={'Order'}
            render={(value) => (
              <TextField
                style={{ color: colors.blue100, cursor: 'pointer' }}
                value={`Order#${value}` || '-'}
                onClick={handleNavigate('order-cruds', value)}
              />
            )}
          />

          <Table.Column
            dataIndex={['order', 'user_id']}
            title={'User'}
            align="center"
            render={(value) => (
              <TextField
                style={{ color: colors.blue100, cursor: 'pointer' }}
                value={`User#${value}` || '-'}
                onClick={handleNavigate('user-cruds', value)}
              />
            )}
          />
          <Table.Column
            dataIndex={'is_deleted'}
            title={'Deleted'}
            render={(value) => <BooleanField value={value} />}
          />
        </Table>
      </List>
    </>
  );
};

export const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;
`;
