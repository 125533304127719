import React from 'react';
import { FilterDropdown, Select } from '@pankod/refine-antd';

interface Props {
  dropdownProps: any;
  placeHolder: string;
  options: { label: string; value: string }[];
  mode?: 'multiple' | 'tags';
}
const DropDownFilter = ({
  dropdownProps,
  placeHolder,
  options,
  mode,
}: Props) => {
  return (
    <FilterDropdown
      {...dropdownProps}
      mapValue={(selectedKeys) => selectedKeys}
    >
      <Select
        style={{ minWidth: 200 }}
        placeholder={placeHolder}
        mode={mode}
        options={options}
      />
    </FilterDropdown>
  );
};
export default DropDownFilter;
