import * as React from 'react';
import { Create, Form, Input, Select, useForm } from '@pankod/refine-antd';
import { Admin } from '../../types';
export const CreateAdmin = () => {
  const { formProps, saveButtonProps } = useForm<Admin>({
    resource: 'admin/create',
    redirect: false,
  });
  return (
    <Create saveButtonProps={saveButtonProps} title={'New Admin'}>
      <Form layout="vertical" {...formProps}>
        <Form.Item label="Name" name="name">
          <Input name="name" autoComplete="off" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input name="email" autoComplete="off" />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input type="number" name="phone" autoComplete="off" />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input type="password" name="password" autoComplete="off" />
        </Form.Item>
        <Form.Item label="Role" name="admin_role">
          <Select
            options={[
              { label: 'super_admin', value: 'super_admin' },
              { label: 'admin', value: 'admin' },
            ]}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
