import * as React from 'react';
import { Create, Form, Input, useForm } from '@pankod/refine-antd';
import { Advertisement } from '../../types';
export const CreateAdvertisement = () => {
  const { formProps, saveButtonProps } = useForm<Advertisement>();
  return (
    <Create saveButtonProps={saveButtonProps} title={'New Advertisement'}>
      <Form layout="vertical" {...formProps}>
        <Form.Item label="Title" name="title">
          <Input name="title" />
        </Form.Item>
        <Form.Item label="Body" name="body">
          <Input name="body" />
        </Form.Item>
        <Form.Item label="Link" name="link">
          <Input name="link" />
        </Form.Item>
      </Form>
    </Create>
  );
};
