import React from 'react';
import {
  BooleanField,
  Grid,
  Show,
  Typography,
  UrlField,
} from '@pankod/refine-antd';
import { useShow } from '@pankod/refine-core';
import dayjs from 'dayjs';
import { DetailsWrapper, ColumnWrapper } from '../../App';
import { Advertisement } from '../../types';
import noPhoto from '../../Assests/noPhoto.png';
import { GET_UPLOAD } from '../../configs';
import styled from 'styled-components';
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const ShowAdvertisement = () => {
  const screens = useBreakpoint();
  const { queryResult } = useShow<Advertisement>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <>
      <Show isLoading={isLoading}>
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>ID</Title>
            <Text>{record?.id || '-'}</Text>
            <Title level={5}>Title</Title>
            <Text>{record?.title || '-'}</Text>
            <Title level={5}>Body</Title>
            <Text>{record?.body || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Link</Title>
            <DescriptionWrapper style={{ marginBottom: 10 }}>
              <UrlField value={record?.link} target="_blank" />
            </DescriptionWrapper>

            <div style={{ marginBottom: 10 }} />
            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Is Deleted</Title>
            <BooleanField value={record?.is_deleted} />
            <Title level={5}>Image</Title>
            <a
              href={
                record?.upload_id ? `${GET_UPLOAD}/${record?.upload_id}` : ' '
              }
              target="_blank"
              rel="noreferrer"
            >
              <Imagesize
                alt={'img'}
                src={
                  record?.upload_id
                    ? `${GET_UPLOAD}/${record?.upload_id}`
                    : noPhoto
                }
              />
            </a>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>
    </>
  );
};
const DescriptionWrapper = styled.div<{ breakPoint?: boolean }>`
  width: ${(props) => (props.breakPoint ? '400px' : '200px')};
`;
const Imagesize = styled.img`
  width: 84px;
  border-radius: 10px;
`;
