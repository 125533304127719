import React, { useEffect } from 'react';
import { Edit, Form, Input, Select, useTable } from '@pankod/refine-antd';
import { AccountStatusArray, IAccount } from '../../types';
import { useFormik } from 'formik';
import { EditRecordAction } from '../../Actions/ConfigsActions';
type Inputs = {
  phone?: number;
  name?: string;
  email?: string;
  gender?: string;
  status?: string;
  points?: number;
};
export const EditProvider = () => {
  const { tableProps, tableQueryResult } = useTable<IAccount>({
    resource: `account-cruds?account_type=provider&id=${+window.location.href.split(
      'edit/'
    )[1]}`,
  });
  const record = tableProps?.dataSource && tableProps?.dataSource[0];
  const { values, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: +'',
      gender: '',
      status: '',
      points: +'',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      if (submittedValues.name !== record?.name) {
        inputs.name = submittedValues.name;
      }
      if (submittedValues.email !== record?.email) {
        inputs.email = submittedValues.email;
      }
      if (submittedValues.phone !== record?.phone) {
        inputs.phone = submittedValues.phone;
      }
      if (submittedValues.gender !== record?.gender) {
        inputs.gender = submittedValues.gender;
      }

      if (submittedValues.status !== record?.status) {
        inputs.status = submittedValues.status;
      }
      if (Object.keys(inputs)?.length > 0) {
        EditRecordAction(
          'account-cruds',
          record?.id,
          inputs,
          tableQueryResult?.refetch
        );
      }
    },
  });
  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };
  useEffect(() => {
    if (record) {
      setFieldValue('name', record?.name);
      setFieldValue('email', record?.email);
      setFieldValue('phone', record?.phone);
      setFieldValue('gender', record?.gender);
      setFieldValue('status', record?.status);
    }
  }, [record, setFieldValue]);
  return (
    <Edit saveButtonProps={buttonProps} title={'Edit Provider'}>
      <Form layout="vertical">
        <Form.Item label="Name">
          <Input name="name" onChange={handleChange} value={values?.name} />
        </Form.Item>
        <Form.Item label="Email">
          <Input name="email" onChange={handleChange} value={values.email} />
        </Form.Item>
        <Form.Item label="Phone">
          <Input name="phone" onChange={handleChange} value={values.phone} />
        </Form.Item>

        <Form.Item label="Gender">
          <Select
            options={[
              { label: 'male', value: 'male' },
              { label: 'female', value: 'female' },
            ]}
            value={values?.gender}
            onChange={(val) => setFieldValue('gender', val)}
          />
        </Form.Item>
        <Form.Item label="Status">
          <Select
            options={AccountStatusArray}
            value={values?.status}
            onChange={(val) => setFieldValue('status', val)}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
