import React from 'react';
import {
  BooleanField,
  Grid,
  Show,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { useNavigation } from '@pankod/refine-core';
import dayjs from 'dayjs';
import { DetailsWrapper, ColumnWrapper } from '../App';
import { Review } from '../types';
import Colors from '../ Theme/ Colors';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const ShowReview = () => {
  const { show } = useNavigation();

  const screens = useBreakpoint();
  const { tableProps, tableQueryResult } = useTable<Review>({
    resource: `review-cruds?&id=${+window.location.href.split('show/')[1]}`,
  });
  const record = tableProps?.dataSource && tableProps?.dataSource[0];
  return (
    <>
      <Show isLoading={tableQueryResult.isLoading}>
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>{"Reporter's name"}</Title>
            <Text
              style={{ color: Colors.blue100, cursor: 'pointer' }}
              onClick={() =>
                record?.created_by.id &&
                show(
                  `${record?.created_by?.account_type}-cruds`,
                  record?.created_by.id
                )
              }
            >
              {record?.created_by.name || '-'}
            </Text>
            <Title level={5}>{"Defendant's name"}</Title>
            <Text
              style={{ color: Colors.blue100, cursor: 'pointer' }}
              onClick={() =>
                record?.account.id &&
                show(
                  `${record?.account?.account_type}-cruds`,
                  record?.account.id
                )
              }
            >
              {record?.account.name || '-'}
            </Text>

            <Title level={5}>{'Order'}</Title>

            <Text
              style={{ color: Colors.blue100, cursor: 'pointer' }}
              onClick={() =>
                record?.order_id && show(`order-cruds`, record?.order_id)
              }
            >
              {record?.order_id ? `Order#${record?.order_id}` : '-'}
            </Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Title</Title>
            <Text>{record?.title || '-'}</Text>
            <Title level={5}>Rating</Title>
            <Text>{record?.rating || '-'}</Text>
            <Title level={5}>Comment</Title>
            <Text>{record?.comment || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Is Report</Title>
            <BooleanField value={record?.is_report} />
            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>
    </>
  );
};
