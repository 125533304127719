import { AuthProvider } from '@pankod/refine-core';
import { ConfigsStore, AccountStore } from '../ Store';
import { LoginAction, LogoutAction } from '../Actions/AuthActions';
const authProvider: AuthProvider = {
  login: async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    return LoginAction({
      phone_number: username,
      password,
      account_type: 'admin',
    });
  },
  logout: async () => {
    return (await ConfigsStore.get('logged_in'))
      ? LogoutAction()
      : Promise.resolve();
  },
  checkError: async (error: any) => {
    Promise.resolve();
    if (error?.message.includes('401') || error?.message.includes('403')) {
      LogoutAction(true);
    } else {
      Promise.resolve();
    }
    return error;
  },
  checkAuth: async () =>
    (await ConfigsStore.get('logged_in'))
      ? Promise.resolve()
      : Promise.reject(),
  getPermissions: async () => {
    const roles = await ConfigsStore.get('adminRoles');
    return Promise.resolve(roles);
  },
  getUserIdentity: async () => {
    const currentAccount = await AccountStore.get('account');
    return Promise.resolve({
      ...((currentAccount as object) || {}),
    });
  },
};
export default authProvider;
