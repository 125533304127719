import React from 'react';
import {
  BooleanField,
  Col,
  Grid,
  List,
  Row,
  Show,
  Table,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { DetailsWrapper, ColumnWrapper, Separator } from '../../App';
import { Order, OrderStop } from '../../types';
import dayjs from 'dayjs';
import Colors from '../../ Theme/ Colors';
import { useNavigation } from '@pankod/refine-core';
import { useLocation } from 'react-router-dom';
import { CustomSider } from '../../Components/Sider';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const OrderStops = () => {
  const { show } = useNavigation();
  const screens = useBreakpoint();

  const id = useLocation()?.state;

  const { tableProps, tableQueryResult } = useTable<OrderStop>({
    resource: `order-stop-cruds?&id=${id}`,
  });

  const stopNotes = useTable<Order>({
    resource: `order-stop-note-cruds?order_stop_id=${id}`,
  });

  const record = tableProps?.dataSource && tableProps?.dataSource[0];

  return (
    <div style={{ background: '#def0ff' }}>
      <Row gutter={[16, 16]}>
        <Col xl={4} lg={4} xs={4}>
          <div style={{ marginTop: -70 }}>
            <CustomSider />
          </div>
        </Col>
        <Col xl={19} xs={19}>
          <div style={{ height: 25 }} />
          <Show isLoading={tableQueryResult.isLoading}>
            <DetailsWrapper screenMD={!!screens.md}>
              <ColumnWrapper screenMD={!!screens.md}>
                <Title level={5}>Order</Title>
                <Text
                  style={{ color: Colors.blue100, cursor: 'pointer' }}
                  onClick={() =>
                    record?.order_id && show('order-cruds', record?.order_id)
                  }
                >
                  {`Order#${record?.order_id || '-'}`}
                </Text>
                {record?.location?.id ? (
                  <>
                    <Title level={5}>City</Title>
                    <Text>{record?.location?.city || '-'}</Text>
                    <Title level={5}>Title</Title>
                    <Text>{record?.location?.title || '-'}</Text>
                    <Title level={5}>Street</Title>
                    <Text>{record?.location?.street || '-'}</Text>
                    <Title level={5}>Area</Title>
                    <Text>{record?.location?.area || '-'}</Text>
                    <Title level={5}>Building</Title>
                    <Text>{record?.location?.building || '-'}</Text>
                    <Title level={5}>Latitude</Title>
                    <Text>{record?.location?.latitude || '-'}</Text>
                    <Title level={5}>Longitude</Title>
                    <Text>{record?.location?.longitude || '-'}</Text>
                  </>
                ) : (
                  <>
                    <Title level={5}>City</Title>
                    <Text>{record?.city || '-'}</Text>
                    <Title level={5}>Title</Title>
                    <Text>{record?.title || '-'}</Text>
                    <Title level={5}>Street</Title>
                    <Text>{record?.street || '-'}</Text>
                    <Title level={5}>Area</Title>
                    <Text>{record?.area || '-'}</Text>
                    <Title level={5}>Building</Title>
                    <Text>{record?.building || '-'}</Text>
                    <Title level={5}>Latitude</Title>
                    <Text>{record?.lat || '-'}</Text>
                    <Title level={5}>Longitude</Title>
                    <Text>{record?.lng || '-'}</Text>
                  </>
                )}
              </ColumnWrapper>
              <ColumnWrapper screenMD={!!screens.md}>
                <Title level={5}>Type</Title>
                <Text>{record?.type || '-'}</Text>
                <Title level={5}>Sequence</Title>
                <Text>{record?.sequence || '-'}</Text>
                <Title level={5}>Invoice Value</Title>
                <Text>{record?.invoice_value || '-'}</Text>
              </ColumnWrapper>
              <ColumnWrapper screenMD={!!screens.md}>
                <Title level={5}>Reached</Title>
                <BooleanField value={record?.reached} />
                <Title level={5}>Reached At</Title>
                <Text>
                  {record?.reached_at
                    ? dayjs(record?.reached_at).format('MMMM DD, YYYY HH:MM')
                    : '-'}
                </Text>
                <Title level={5}>Created At</Title>
                <Text>
                  {record?.created_at
                    ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                    : '-'}
                </Text>
              </ColumnWrapper>
            </DetailsWrapper>
          </Show>
          <Separator />
          <List
            title={`${'Order Stop Notes'}`}
            pageHeaderProps={{ extra: <></> }}
          >
            <Table {...stopNotes.tableProps} rowKey="id">
              <Table.Column
                dataIndex={'id'}
                title={'Id'}
                render={(value) => <TextField value={value || '-'} />}
              />
              <Table.Column
                dataIndex={'note'}
                title={'Note'}
                render={(value) => (
                  <Tooltip title={value || '-'}>
                    <div style={{ width: 350 }}>
                      <TextField value={value || '-'} />
                    </div>
                  </Tooltip>
                )}
              />
              <Table.Column
                dataIndex="is_deleted"
                title={'Deleted'}
                align="center"
                render={(value) => <BooleanField value={value} />}
              />
              <Table.Column
                dataIndex="created_at"
                title={'Created at'}
                render={(value) => (
                  <TextField
                    value={
                      value ? dayjs(value).format('MMMM DD, YYYY HH:MM') : '-'
                    }
                  />
                )}
              />
            </Table>
          </List>
          <Separator />
          {/* <List title={`${'Order Items'}`} pageHeaderProps={{ extra: <></> }}>
        <Table dataSource={record?.orderItems} rowKey="id">
          <Table.Column
            dataIndex={'id'}
            title={'Id'}
            render={(value) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={'title'}
            title={'Title'}
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex={'quantity'}
            title={'Quantity'}
            align="center"
            render={(value) => <TextField value={value || '0'} />}
          />
          <Table.Column
            dataIndex="purchased"
            title={'Purchased'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex="created_at"
            title={'Created at'}
            render={(value) => (
              <TextField
                value={value ? dayjs(value).format('MMMM DD, YYYY HH:MM') : '-'}
              />
            )}
          />
        </Table>
      </List> */}
        </Col>
      </Row>
    </div>
  );
};
