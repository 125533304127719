const colors = {
  primaryDark: '#642583',
  primaryLight: '#7B1CAB',
  lightPurple: '#cfbce0',
  darkGrey: '#36393F',
  // Secondary
  secondaryDark: '#257583',
  secondaryLight: '#1CA0AA',
  // Tertiary
  tertiaryDark: '#254483',
  tertiaryLight: '#1C4BAA',
  // Quaternary
  quaternaryDark: '#852535',
  quaternaryLight: '#AA1C34',
  // Neutrals
  neutral100: '#FAFAFA',
  neutral200: '#F3F5FC',
  neutral300: '#E6E6EE',
  neutral400: '#C8C8D4',
  neutral500: '#ABABBA',
  neutral600: '#7C7C8D',
  neutral700: '#575761',
  neutral800: '#333333',
  neutral900: '#275CE3',
  neutral1000: '#eeefed',
  // blue
  blue100: '#428bca',
  blue200: '#34a6ff',
  blue300: '#1976d2',
  // Background colors
  backgroundColor: '#E5E5E5',
  cardPickerBackground: '#fbfbfb',
  // white & black
  white: '#FFFFFF',
  black: '#000000',
  gray: '#979797',
  // Splash Screen Extracted Colors
  darkPurple1: '#2b0052',
  darkPurple2: '#1f0039',
  darkPurple3: '#170028',
  darkPurple4: '#0c0016',
  darkPurple5: '#0c0016',
  darkPurple6: '#020106',
};
export default colors;
