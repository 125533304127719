import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Edit,
  Form,
  Input,
  Space,
  Upload,
  useForm,
} from '@pankod/refine-antd';
import { Advertisement } from '../../types';
import { GET_UPLOAD, UPLOAD_URI } from '../../configs';
import noPhoto from '../../Assests/noPhoto.png';
import { AccountStore, ConfigsStore } from '../../ Store';
import { useFormik } from 'formik';
import { EditRecordAction } from '../../Actions/ConfigsActions';
export const EditAdvertisement = () => {
  const [headers, setHeaders] = useState<{ token: string; device_id: string }>({
    device_id: '',
    token: '',
  });

  const getHeaders = async () => {
    const device_id = await ConfigsStore.get('device_id');
    const account = await AccountStore.get('account');
    setHeaders({
      token: account?.tokens?.access_token || '',
      device_id: device_id || '',
    });
  };
  const { queryResult } = useForm<Advertisement>();
  const record = queryResult?.data?.data;

  const { handleSubmit, handleChange, setFieldValue, values } = useFormik({
    initialValues: {
      id: record?.id,
      upload_id: {} as any,
      title: '',
      body: '',
      link: '',
    },

    onSubmit: async (submittedValues) => {
      const inputs: any = {
        id: record?.id,
      };
      if (
        values?.upload_id?.file?.response?.length > 0 &&
        values?.upload_id?.file?.response[0]?.id
      ) {
        inputs.upload_id =
          values?.upload_id?.file?.response?.length > 0 &&
          values?.upload_id?.file?.response[0]?.id;
      }
      if (submittedValues.title !== record?.title) {
        inputs.title = submittedValues.title;
      }
      if (submittedValues.body !== record?.body) {
        inputs.body = submittedValues.body;
      }
      if (submittedValues.link !== record?.link) {
        inputs.link = submittedValues.link;
      }
      if (Object.keys(inputs)?.length > 0) {
        EditRecordAction('advertisement-cruds', record?.id, inputs);
      }
    },
  });

  useEffect(() => {
    if (record) {
      setFieldValue('title', record?.title);
      setFieldValue('body', record?.body);
      setFieldValue('link', record?.link);
    }
    getHeaders();
  }, [record, setFieldValue]);

  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };

  return (
    <Edit saveButtonProps={buttonProps} title={'Edit Advertisement'}>
      <Form layout="vertical">
        <Form.Item label="Title">
          <Input name="title" onChange={handleChange} value={values.title} />
        </Form.Item>
        <Form.Item label="Body">
          <Input name="body" onChange={handleChange} value={values.body} />
        </Form.Item>
        <Form.Item label="Link">
          <Input name="link" onChange={handleChange} value={values.link} />
        </Form.Item>
      </Form>

      <Form.Item noStyle>
        <Upload.Dragger
          name="file"
          action={UPLOAD_URI}
          listType="picture"
          showUploadList={false}
          maxCount={1}
          onChange={(file) => setFieldValue('upload_id', file)}
          headers={{
            authorization: `Bearer ${headers?.token}`,
            device_id: headers.device_id,
          }}
        >
          <Space direction="vertical" size={2}>
            <Avatar
              style={{
                width: '50%',
                height: '50%',
                maxWidth: '150px',
                borderRadius: 5,
              }}
              src={
                values?.upload_id?.file?.response?.length > 0 &&
                values?.upload_id?.file?.response[0]?.id
                  ? `${GET_UPLOAD}/${values?.upload_id?.file?.response[0]?.id}`
                  : record?.upload_id
                  ? `${GET_UPLOAD}/${record?.upload_id}`
                  : noPhoto
              }
              alt="Banner"
            />
          </Space>
        </Upload.Dragger>
      </Form.Item>
    </Edit>
  );
};
