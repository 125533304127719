import React, { useEffect } from 'react';
import {
  BooleanField,
  List,
  RefreshButton,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { IAccount, Thread } from '../../types';
import { Search } from '../../Components/ Search';
import { useNavigation } from '@pankod/refine-core';
import { RiWechatLine } from 'react-icons/ri';
import ChatModal from '../../Components/Chat';
import { DeleteButtons } from '../../Components/DeleteButton';
import colors from '../../ Theme/ Colors';

export const HelpCenterListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const [visible, setVisible] = React.useState<Thread | null>();
  const { show } = useNavigation();
  const { tableProps, tableQueryResult } = useTable<IAccount>({
    syncWithLocation: true,
    resource: 'thread-cruds/support-threads',
  });

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  }

  const handleNavigate = (name: string, id: number) => () => {
    show(name, id);
  };

  const closeModal = () => {
    setVisible(null);
    tableQueryResult?.refetch();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      tableQueryResult?.refetch();
    }, 10000);
    return () => clearInterval(interval);
  }, [tableQueryResult]);

  return (
    <List
      title={`${'Help Center'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="account-cruds"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            <RefreshButton
              onClick={() => tableQueryResult?.refetch()}
              style={{ marginLeft: 10 }}
            />
          </div>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['first_participant']}
          title={'Participant'}
          render={(value: IAccount, record: IAccount) => (
            <TextField
              style={{ color: colors.blue100, cursor: 'pointer' }}
              value={
                searchResults?.length > 0
                  ? `${`${record?.name} #${record?.id}` || '-'}`
                  : `${`${value?.name} #${value?.id}` || '-'}`
              }
              onClick={handleNavigate(
                searchResults?.length > 0
                  ? `${record?.account_type}-cruds`
                  : `${value?.account_type}-cruds`,
                searchResults?.length > 0 ? record?.id : value?.id
              )}
            />
          )}
        />

        <Table.Column
          dataIndex={
            searchResults?.length > 0
              ? 'account_type'
              : ['first_participant', 'account_type']
          }
          title={'Account Type'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={
            searchResults?.length > 0 ? 'phone' : ['first_participant', 'phone']
          }
          title={'Phone'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={searchResults?.length > 0 ? 'thread_fp' : 'message'}
          title={'Messages'}
          align="center"
          render={(value) => {
            return (
              <TextField
                value={
                  searchResults?.length > 0
                    ? value
                        ?.filter((item: any) => item?.is_support_thread)
                        .map((item: any) => item?.message?.length)
                    : value?.length || '0'
                }
              />
            );
          }}
        />

        <Table.Column
          dataIndex={'message'}
          title={'Unread Messages'}
          align="center"
          render={(value, record) => {
            let messagesNumber;
            searchResults?.length > 0
              ? // @ts-ignore
                (messagesNumber = record?.thread_fp
                  ?.filter((item: any) => item?.is_support_thread)[0]
                  ?.message?.filter(
                    (item: { is_read: boolean }) => !item?.is_read
                  )?.length)
              : (messagesNumber = value?.filter(
                  (item: { is_read: boolean }) => !item?.is_read
                )?.length);
            return <TextField value={messagesNumber || '0'} />;
          }}
        />

        <Table.Column
          dataIndex="is_deleted"
          title={'Deleted'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />

        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          align="center"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <RiWechatLine
                  style={{ marginTop: 5 }}
                  size={20}
                  color={'#1a76d2'}
                  className="icons"
                  onClick={() => setVisible(record)}
                />
                <DeleteButtons
                  id={record?.id}
                  is_deleted={record.is_deleted}
                  path={'thread-cruds'}
                  handleRefetch={tableQueryResult.refetch}
                />
              </Space>
            );
          }}
        />
      </Table>
      {!!visible && (
        <ChatModal
          setVisible={closeModal}
          visible={!!visible}
          info={visible}
          thread_id={
            visible?.thread_fp?.filter(
              (item: any) => item?.is_support_thread
            )[0]?.id
              ? visible?.thread_fp?.filter(
                  (item: any) => item?.is_support_thread
                )[0]?.id
              : searchResults?.length > 0
              ? visible?.message[0]?.thread_id
              : visible?.id
          }
        />
      )}
    </List>
  );
};
