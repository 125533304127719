export type AxiosHeaders = {
  defaults: {
    headers: {
      api_key?: string;
      Authorization?: string;
      device_id?: string;
    };
  };
};

export type IAccount = {
  id: number;
  first_name: string;
  last_name: string;
  phone: number;
  name: string;
  email: string;
  account_type: string;
  password: string;
  upload_id: number;
  last_message_sent_at: Date;
  tokens: {
    access_token: string;
    refresh_token: string;
  };
  admin: {
    id: number;
    account_id: number;
    role: string;
  };
  provider: {
    account_id: number;
    created_at: Date;
    current_type: string;
    id: number;
    is_available: Boolean;
    lat: null;
    lng: null;
    provider_type: string;
    updated_at: Date;
  };
  providerDocuments: {
    account_id: number;
    created_at: Date;
    id: number;
    is_deleted: boolean;
    provider_document_type: string;
    status: string;
    updated_at: Date;
    upload_id: number;
  }[];
  address: string;
  badges_count: 0;
  birthdate: string;
  completed_journeys_count: number;
  completed_tracks_count: number;
  created_at: string;
  gender: string;
  is_subscribed: boolean;
  is_new_user: boolean;
  location: string;
  meditation_experience: string;
  object_type: string;
  plays_count: 0;
  profile_picture_url: string;
  seconds_listened: number;
  status: 'active' | 'under_approval' | 'archived' | 'deleted';
  updated_at: Date;
  points?: number;
  rate_count: number;
  average_rate: number;
  is_email_confirmed: boolean;
  is_phone_confirmed: boolean;
  dob: Date;
};
export type Advertisement = {
  id: number;
  title: string;
  body: string;
  upload_id: number;
  link: string;
  is_deleted: boolean;
  created_at: Date;
  updated_at: Date;
};
export type Tier = {
  tier: string;
  id: number;
  points_threshold: number;
  title: string;
  description: string;
  extra_ratio: number;
  created_at: Date;
  updated_at: Date;
};
export type Category = {
  id: number;
  created_at: Date;
  updated_at: Date;
  title: string;
  description: string;
};
export type Reward = {
  created_at: Date;
  updated_at: Date;
  id: number;
  is_elite: boolean;
  value: number;
  valid_for_months: number;
  account_id: number;
  title: string;
  description: string;
  points: number;
  is_deleted: boolean;
  count: number;
};
export type Activereward = {
  created_at: Date;
  updated_at: Date;
  id: number;
  account_id: number;
  reward_id: number;
  used: boolean;
  is_deleted: boolean;
};
export type Admin = {
  account: IAccount;
  account_id: boolean;
  role: string;
  id: number;
  created_at: Date;
  updated_at: Date;
  is_deleted: Boolean;
};
export type Provider = {
  provider_type: string;
  current_type: string;
  id: number;
  account_id: number;
  created_at: Date;
  updated_at: Date;
  is_available: boolean;
};
export type Settlement = {
  created_at: Date;
  gomint_earning: number;
  id: number;
  gomint_commission_percentage: number;
  is_deleted: boolean;
  order: number;
  provider: Provider;
  order_id: number;
  provider_earning: number;
  provider_id: number;
  total_order_amount: number;
  updated_at: '2022-10-19T12:52:21.260Z';
};
export type Review = {
  account: IAccount;
  account_id: number;
  comment: string;
  created_at: Date;
  created_by_id: number;
  created_by: IAccount;
  is_report: boolean;
  order: number;
  order_id: number;
  rating: number;
  title: string;
  updated_at: Date;
};
export type Systemconfig = {
  base_fare?: number;
  created_at?: Date;
  driver_delivery_min_fare?: number;
  elite_threshold?: number;
  fee_per_item?: number;
  fee_per_kilometer?: number;
  fee_per_min_delivery?: number;
  fee_per_min_shop?: number;
  fee_per_stop?: number;
  first_stop_fee?: number;
  gomint_order_percentage?: number;
  id?: number;
  is_active?: boolean;
  max_extra_fees?: number;
  order_acceptance_buffer?: number;
  points_per_jod?: number;
  support_phone?: string;
  required_documents?: string[];
  base_percentage?: number;
  base_threshold?: number;
  dispatching_timeout?: number;
  max_order_value?: number;
  order_schedule_buffer?: number;
  percentage_1?: number;
  percentage_2?: number;
  percentage_3?: number;
  threshold_1?: number;
  threshold_2?: number;
  threshold_3?: number;
};
export type Order = {
  active_reward_id: number;
  actual_cost: number;
  actual_time: number;
  canceled_at: Date;
  review: Review[];
  cart_description: string;
  completed_at: Date;
  contact_number: string;
  contact_person: string;
  cost_estimation: number;
  created_at: Date;
  currently_dispatched_to: number;
  dispatched_at: Date;
  id: number;
  last_status_change_at: Date;
  delivery_fee: number;
  service_fee: number;
  total_cost_with_fees: number;
  orderItems: {
    created_at: Date;
    id: number;
    order_id: number;
    order_stop_id: number;
    purchased: boolean;
    quantity: number;
    title: string;
    updated_at: Date;
  }[];
  orderStops: {
    alias: string;
    created_at: Date;
    id: number;
    invoicee_value: number;
    lat: number;
    lng: number;
    location_id: number;
    order_id: number;
    reached: boolean;
    reached_at: Date;
    sequence: number;
    type: string;
    updated_at: Date;
  }[];
  order_type: string;
  payment_card: paymentcard;
  payment_card_id: number;
  payment_method: string;
  provider: string;
  provider_id: number;
  provider_notes: string;
  provider_type: string;
  rewarded_points: number;
  scheduled_at: Date;
  shopping_cost_estimation: string;
  started_at: Date;
  status: string;
  time_estimation: number;
  total_distance: number;
  updated_at: Date;
  user: IAccount;
  user_id: number;
};
export type User = {
  account_id: number;
  created_at: Date;
  id: number;
  tier_id: number;
  updated_at: Date;
};

export type OrderStop = {
  alias: string;
  created_at: Date;
  id: number;
  invoice_value: number;
  lat: number;
  lng: number;
  location_id: number;
  order_id: number;
  reached: boolean;
  reached_at: Date;
  sequence: number;
  type: string;
  updated_at: Date;
  area: string;
  building: string;
  city: string;
  location: {
    account_id: number;
    area: string;
    building: string;
    city: string;
    created_at: Date;
    id: number;
    is_default: boolean;
    is_deleted: boolean;
    latitude: number;
    longitude: number;
    street: string;
    title: string;
    type: string;
  };
  locationType: string;
  order: Order;
  orderStopNote: OrderStopNote;
  street: string;
  title: string;
};

export type OrderStopNote = {
  id: number;
  note: string;
  order_stop_id: number;
};

export type paymentcard = {
  account_id: number;
  brand: string;
  card_token: string;
  created_at: Date;
  exp_month: number;
  exp_year: number;
  id: number;
  last4: string;
  updated_at: Date;
};
export enum OsEnum {
  ios = 'ios',
  android = 'android',
  browser = 'browser',
}
export enum Language {
  ar = 'ar',
  en = 'ar',
}
export const AccountType = ['user', 'admin', 'provider'];

export enum DocumentStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
}

export enum AccountStatus {
  active = 'active',
  under_approval = 'under_approval',
  archived = 'archived',
  deleted = 'deleted',
}

export type Thread = {
  id: number;
  first_participant_id: number;
  second_participant_id: number;
  order_id: number;
  is_deleted: boolean;
  deleted_by_id: number;
  is_support_thread: boolean;
  created_at: Date;
  updated_at: Date;
  account_id: number;
  first_participant: IAccount;
  thread_fp: { id: number; message: Message[] }[];
  uplaod: { id: number; file_name: string };
  message: Message[];
};

export type Message = {
  body: string;
  created_at: Date;
  id: number;
  is_read: boolean;
  sender_id: number;
  thread_id: number;
  type: string;
  updated_at: Date;
  upload_id: number;
};

export type ThreadMessages = {
  id: number;
  thread_id: number;
  sender_id: number;
  body: string;
  type: string;
  upload_id: number;
  is_read: boolean;
  created_at: string;
  updated_at: Date;
  sender: IAccount;
};

export const AccountStatusArray = [
  { value: AccountStatus.active, label: 'Active' },
  { value: AccountStatus.under_approval, label: 'Under Approval' },
  { value: AccountStatus.archived, label: 'Archived' },
  { value: AccountStatus.deleted, label: 'Deleted' },
];
