import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Sentry.init({
  dsn: 'https://9ab6530b56d5429d97492b5b099f7b43@sentry.wtmsrv.com/23',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  // send local storage contents to sentry  (for debugging) with each event
  beforeSend(event) {
    event.contexts = {
      ...event.contexts,
      'State (localStorage)': {
        'Configs/store': window.localStorage.getItem('Configs/store'),
        'Account/store': window.localStorage.getItem('Account/store'),
      },
    };
    return event;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  environment: window.location.host.includes('admin.gomint.com')
    ? 'production'
    : window.location.host.includes('admin.gomint.staging.wtmsrv.com')
    ? 'staging'
    : 'development',
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
