import React from 'react';
import {
  BooleanField,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { EditButton, ShowButton } from '@pankod/refine-mui';
import { Settlement } from '../types';
import SearchModal from '../Components/SearchModal';
import useModal from '../hooks/useModal';
import { Search } from '../Components/ Search';
import { useNavigation } from '@pankod/refine-core';
import { DeleteButtons } from '../Components/DeleteButton';
import DropDownFilter from '../Components/DropDownFilter';
import colors from '../ Theme/ Colors';
export const SettlementListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const { closeModal, isVisible } = useModal();
  const { show } = useNavigation();

  const { tableProps, tableQueryResult } = useTable<Settlement>({
    syncWithLocation: true,
  });
  //   const handleExportList = () => {
  //     ExportList(tableProps.dataSource || [], 'UniverseMessages');
  //   };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  }
  const handleNavigate = (name: string, id: string) => () => {
    show(name, id);
  };
  return (
    <List
      title={`${'Settlement'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="provider-settlement-cruds"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            {/* <Button onClick={openModal}>Filter</Button> */}
          </div>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="order_id"
          title={'Order'}
          render={(value) => (
            <TextField
              value={`Order#${value}` || '-'}
              style={{ color: colors.blue100, cursor: 'pointer' }}
              onClick={handleNavigate('order-cruds', value)}
            />
          )}
        />
        <Table.Column
          dataIndex={['provider', 'account_id']}
          title={'Provider'}
          render={(value) => (
            <TextField
              value={`Provider#${value}` || '-'}
              style={{ color: colors.blue100, cursor: 'pointer' }}
              onClick={handleNavigate('provider-cruds', value)}
            />
          )}
        />
        <Table.Column
          dataIndex={'gomint_earning'}
          title={'Gomint Earning'}
          render={(value) => (
            <TextField value={value ? Number(value)?.toFixed(2) : '-'} />
          )}
        />

        <Table.Column
          dataIndex="total_order_amount"
          title={'Total Order Amount'}
          render={(value) => (
            <TextField value={value ? Number(value)?.toFixed(2) : '-'} />
          )}
        />
        <Table.Column
          dataIndex="is_deleted"
          title={'Deleted'}
          align="center"
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Status'}
              options={[
                { label: 'Active', value: 'false' },
                { label: 'Deleted', value: 'true' },
              ]}
            />
          )}
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButtons
                  id={record?.id}
                  is_deleted={record.is_deleted}
                  path={'provider-settlement-cruds'}
                  handleRefetch={tableQueryResult.refetch}
                />
              </Space>
            );
          }}
        />
      </Table>
      <SearchModal
        visible={isVisible}
        closeModal={closeModal}
        type="settlement"
      />
    </List>
  );
};
