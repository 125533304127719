import { AccountStore, ConfigsStore } from '../ Store';
import { API_KEY } from '../configs';
import axios from 'axios';
import { AxiosHeaders, IAccount } from '../types';
import dayjs from 'dayjs';
import { apiRequest } from '../ Utils/APIs';
import { notification } from '@pankod/refine-antd';
import * as Sentry from '@sentry/react';
import { allResources } from '../App';

export const axiosInstance = axios.create() as AxiosHeaders;
const setHeaders = async () => {
  const Account = await AccountStore.get('account');
  if (Account?.tokens.access_token) {
    axiosInstance.defaults.headers = {
      api_key: API_KEY,
      Authorization: `Bearer ${Account?.tokens.access_token}`,
      device_id: (await ConfigsStore.get('device_id')) || '',
    };
  } else {
    axiosInstance.defaults.headers = {
      api_key: API_KEY,
    };
  }
};
setHeaders();
export const LoginAction = async (LoginInput: {
  phone_number: string;
  password: string;
  account_type: string;
}) => {
  try {
    const { data: accountData } = await apiRequest<IAccount>({
      url: 'admin/login',
      method: 'POST',
      data: LoginInput,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
      },
    });
    if (!accountData) throw new Error('Account Not Found!');
    await AccountStore.set('account', accountData);
    await ConfigsStore.set('logged_in', true);
    await ConfigsStore.set('resetPasswordEmail', '');
    await ConfigsStore.set('adminRoles', accountData?.admin?.role);
    if (await ConfigsStore.get('rememberMe')) {
      const cookies = {
        value: await ConfigsStore.get('logged_in'),
        expiry: dayjs(Date()).add(15, 'd').toDate(),
      };
      await ConfigsStore.set('cookieExpiration', cookies);
    }
    axiosInstance.defaults.headers = {
      // @ts-ignore
      Authorization: `Bearer ${accountData.tokens.access_token}`,
      api_key: API_KEY,
      device_id: await ConfigsStore.get('device_id'),
    };
    Sentry.setUser({
      id: accountData?.id.toString(),
      email: accountData?.email,
      name: accountData?.name,
      device: await ConfigsStore.get('device_id'),
    });
    const currentResources = (await ConfigsStore.get('resources')) || [];
    const resources = allResources?.filter((resource) => {
      if (accountData?.admin?.role === 'admin') {
        if (resource.name !== 'admin-cruds') {
          return resource;
        }
      } else {
        return resource;
      }
    });
    await ConfigsStore.set('resources', resources);
    if (currentResources?.length !== resources?.length) {
      if (accountData?.admin?.role === 'admin') {
        window.location.href = '/user-cruds';
      } else {
        window.location.href = '/admin-cruds';
      }
    }
  } catch (error) {
    throw new Error('Incorrect email or password');
  }
};
export const LogoutAction = async (withRefresh?: boolean) => {
  await AccountStore.set('account', {});
  await ConfigsStore.set('logged_in', false);
  await ConfigsStore.set('rememberMe', false);
  await ConfigsStore.set('cookieExpiration', '');
  await ConfigsStore.set('adminRoles', '');
  await ConfigsStore.set('resetPasswordEmail', '');
  sessionStorage.clear();
  // set sentry user to null on logout
  Sentry.setUser(null);
  if (withRefresh) {
    await window.location.reload();
  }
  return Promise.resolve();
};
export const ForgotPasswordAction = async (
  inputs: { email: string },
  navigateToVerification: (email: string) => void,
  handleDisableButton?: (value: boolean) => void
) => {
  handleDisableButton && handleDisableButton(true);
  try {
    const { data: ForgotPasswordData } = await apiRequest<boolean>({
      url: 'admin/forget-password',
      method: 'POST',
      data: inputs,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
      },
    });

    if (!ForgotPasswordData) {
      notification.error({
        message: 'Error',
        description: 'لم يتم العثور على بريد الكتروني المدخل',
      });
    }
    handleDisableButton && handleDisableButton(false);
    navigateToVerification(inputs.email);
    await ConfigsStore.set('resetPasswordEmail', inputs?.email);
  } catch (error) {
    handleDisableButton && handleDisableButton(false);
    notification.error({
      message: 'Error',
      // description: '  البريد البريد الالكتروني غير صحيح',
    });
  }
};
export const ResetPasswordAction = async (
  password: string,
  navigate?: () => void
) => {
  const inputs = { password };
  try {
    const { data: resetPasswordData } = await apiRequest<boolean>({
      url: 'admin/reset-password',
      method: 'POST',
      data: inputs,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${await ConfigsStore.get('resetPasswordToken')}`,
      },
    });
    if (!resetPasswordData) {
      notification.error({
        message: 'Error',
        // description: ' ' ,
      });
    }
    notification.success({
      message: 'Error',
      // description: '    '',
    });
    navigate && navigate();
    await ConfigsStore.set('resetPasswordEmail', '');
    await ConfigsStore.set('resetPasswordToken', '');
  } catch (error: any) {
    notification.error({
      message: 'Error',
    });
  }
};
export const VerificationAction = async (
  code: number,
  phone_number?: string,
  navigate?: () => void
) => {
  const inputs = { code, phone_number };
  try {
    const { data: verificationData } = await apiRequest<any>({
      url: 'admin/verify-code',
      method: 'POST',
      data: inputs,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${await AccountStore.get('account')}`,
      },
    });
    if (!verificationData) {
      notification.error({
        message: 'Error',
        // description: '    '',
      });
    }
    await ConfigsStore.set(
      'resetPasswordToken',
      verificationData?.tokens?.access_token
    );
    navigate && navigate();
  } catch (error: any) {
    notification.error({
      message: 'Error',
      // description: '',
    });
  }
};
