import React from 'react';
import {
  BooleanField,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { EditButton, ShowButton } from '@pankod/refine-mui';
import { IAccount } from '../../types';
import { Search } from '../../Components/ Search';
import SearchModal from '../../Components/SearchModal';
import useModal from '../../hooks/useModal';
import { DeleteButtons } from '../../Components/DeleteButton';
import DropDownFilter from '../../Components/DropDownFilter';
export const ProviderListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const { closeModal, isVisible } = useModal();
  const { tableProps, tableQueryResult } = useTable<IAccount>({
    resource: 'account-cruds?account_type=provider',
    syncWithLocation: true,
  });
  //   const handleExportList = () => {
  //     ExportList(tableProps.dataSource || [], 'UniverseMessages');
  //   };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  }
  return (
    <List
      title={`${'Provider'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="account-cruds"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              account_type="provider"
            />
            {/* <Button onClick={openModal}>Filter</Button> */}
          </div>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'name'}
          title={'Name'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="email"
          title={'Email'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex="phone"
          title={'Phone'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="gender"
          title={'Gender'}
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Gender'}
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
            />
          )}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="status"
          title={'Status'}
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Status'}
              options={[
                { label: 'Active', value: 'active' },
                { label: 'Under Approval', value: 'under_approval' },
                { label: 'Archived', value: 'archived' },
                { label: 'Deleted', value: 'deleted' },
              ]}
            />
          )}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['provider', 'provider_type']}
          title={'Provider type'}
          align="center"
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['provider', 'current_type']}
          title={'Current type'}
          align="center"
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="average_rate"
          title={'Average Rate'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex="request_deletion"
          title={'Requested Deletion'}
          filterDropdown={(props) => (
            <DropDownFilter
              dropdownProps={props}
              placeHolder={'Requested Deletion'}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
            />
          )}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />

        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          align="center"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButtons
                  id={record?.id}
                  status={record.status}
                  path={'account-cruds'}
                  requested_deletion={record.request_deletion}
                  handleRefetch={tableQueryResult.refetch}
                />
              </Space>
            );
          }}
        />
      </Table>
      <SearchModal visible={isVisible} closeModal={closeModal} type="user" />
    </List>
  );
};
