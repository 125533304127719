import { Grid, AntdLayout, Menu, Icons } from '@pankod/refine-antd';
import { useMenu, useNavigation } from '@pankod/refine-core';
import React, { useState } from 'react';
import { antLayoutSider, antLayoutSiderMobile } from './styles';
import logo from '.././../ images/logo.png';

export const CustomSider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { menuItems, selectedKey } = useMenu();
  const breakpoint = Grid.useBreakpoint();
  const { push } = useNavigation();

  const isMobile = !breakpoint.lg;

  return (
    <AntdLayout.Sider
      collapsible
      collapsedWidth={isMobile ? 0 : 80}
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      <div style={{ height: 85 }} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {collapsed ? (
          <img
            style={{ marginBottom: 20, alignSelf: 'center' }}
            src={logo}
            width="50px"
            alt="logo"
          />
        ) : (
          <img
            style={{ marginBottom: 20, alignSelf: 'center' }}
            src={logo}
            width="85px"
            alt="logo"
          />
        )}
      </div>
      <Menu
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={({ key }) => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }

          push(key as string);
        }}
      >
        {menuItems.map(({ icon, label, route }) => {
          const isSelected = route === selectedKey;
          return (
            <Menu.Item
              style={{
                fontWeight: isSelected ? 'bold' : 'normal',
              }}
              key={route}
              icon={icon}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: 13.3,
                }}
              >
                {label}
                {!collapsed && isSelected && <Icons.RightOutlined />}
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    </AntdLayout.Sider>
  );
};
