import * as React from 'react';
import {
  Edit,
  Form,
  Grid,
  Input,
  InputNumber,
  Select,
  useForm,
  PageHeader,
  Tooltip,
  Button,
} from '@pankod/refine-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Systemconfig } from '../types';
import { ColumnWrapper, DetailsWrapper } from '../App';
import { useFormik } from 'formik';
import { EditRecordAction } from '../Actions/ConfigsActions';
const { useBreakpoint } = Grid;
export const EditSystemConfig = () => {
  const { queryResult } = useForm<Systemconfig>();
  const record = queryResult?.data?.data;
  const screens = useBreakpoint();

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      id: record?.id,
      base_fare: record?.base_fare,
      driver_delivery_min_fare: record?.driver_delivery_min_fare,
      elite_threshold: record?.elite_threshold,
      fee_per_item: record?.fee_per_item,
      fee_per_kilometer: record?.fee_per_kilometer,
      fee_per_min_delivery: record?.fee_per_min_delivery,
      fee_per_min_shop: record?.fee_per_min_shop,
      fee_per_stop: record?.fee_per_stop,
      first_stop_fee: record?.first_stop_fee,
      gomint_order_percentage: record?.gomint_order_percentage,
      max_extra_fees: record?.max_extra_fees,
      order_acceptance_buffer: record?.order_acceptance_buffer,
      points_per_jod: record?.points_per_jod,
      support_phone: record?.support_phone,
      is_active: record?.is_active,
      required_documents: record?.required_documents,
      required_docs_en: '',
      required_docs_ar: '',
      removed_doc: '',
      base_percentage: record?.base_percentage,
      base_threshold: record?.base_threshold,
      dispatching_timeout: record?.dispatching_timeout,
      max_order_value: record?.max_order_value,
      order_schedule_buffer: record?.order_schedule_buffer,
      percentage_1: record?.percentage_1,
      percentage_2: record?.percentage_2,
      percentage_3: record?.percentage_3,
      threshold_1: record?.threshold_1,
      threshold_2: record?.threshold_2,
      threshold_3: record?.threshold_3,
    },

    onSubmit: async (submittedValues) => {
      const inputs: Systemconfig = {};
      if (submittedValues.base_fare !== record?.base_fare) {
        inputs.base_fare = submittedValues.base_fare;
      }
      if (
        submittedValues.driver_delivery_min_fare !==
        record?.driver_delivery_min_fare
      ) {
        inputs.driver_delivery_min_fare =
          submittedValues.driver_delivery_min_fare;
      }
      if (submittedValues.elite_threshold !== record?.elite_threshold) {
        inputs.elite_threshold = submittedValues.elite_threshold;
      }
      if (submittedValues.fee_per_item !== record?.fee_per_item) {
        inputs.fee_per_item = submittedValues.fee_per_item;
      }
      if (submittedValues.fee_per_kilometer !== record?.fee_per_kilometer) {
        inputs.fee_per_kilometer = submittedValues.fee_per_kilometer;
      }
      if (
        submittedValues.fee_per_min_delivery !== record?.fee_per_min_delivery
      ) {
        inputs.fee_per_min_delivery = submittedValues.fee_per_min_delivery;
      }
      if (submittedValues.fee_per_min_shop !== record?.fee_per_min_shop) {
        inputs.fee_per_min_shop = submittedValues.fee_per_min_shop;
      }
      if (submittedValues.fee_per_stop !== record?.fee_per_stop) {
        inputs.fee_per_stop = submittedValues.fee_per_stop;
      }
      if (submittedValues.first_stop_fee !== record?.first_stop_fee) {
        inputs.first_stop_fee = submittedValues.first_stop_fee;
      }
      if (
        submittedValues.gomint_order_percentage !==
        record?.gomint_order_percentage
      ) {
        inputs.gomint_order_percentage =
          submittedValues.gomint_order_percentage;
      }
      if (submittedValues.max_extra_fees !== record?.max_extra_fees) {
        inputs.max_extra_fees = submittedValues.max_extra_fees;
      }
      if (
        submittedValues.order_acceptance_buffer !==
        record?.order_acceptance_buffer
      ) {
        inputs.order_acceptance_buffer =
          submittedValues.order_acceptance_buffer;
      }
      if (submittedValues.points_per_jod !== record?.points_per_jod) {
        inputs.points_per_jod = submittedValues.points_per_jod;
      }
      if (submittedValues.support_phone !== record?.support_phone) {
        inputs.support_phone = submittedValues.support_phone;
      }
      if (
        submittedValues.required_docs_en &&
        submittedValues.required_docs_ar
      ) {
        const required_documents: string[] = record?.required_documents || [];
        const mergedDoc = `${submittedValues.required_docs_en} -- ${submittedValues.required_docs_ar}`;
        submittedValues.required_documents &&
          required_documents?.push(mergedDoc);
        inputs.required_documents = required_documents;
      }
      if (submittedValues.removed_doc) {
        const docs: string[] | never[] = record?.required_documents || [];
        if (docs?.includes(submittedValues.removed_doc)) {
          const index = docs?.indexOf(submittedValues.removed_doc);
          docs?.splice(index, 1);
          inputs.required_documents = docs;
        }
      }

      if (submittedValues.base_threshold !== record?.base_threshold) {
        inputs.base_threshold = submittedValues.base_threshold;
      }
      if (submittedValues.dispatching_timeout !== record?.dispatching_timeout) {
        inputs.dispatching_timeout = submittedValues.dispatching_timeout;
      }
      if (submittedValues.max_order_value !== record?.max_order_value) {
        inputs.max_order_value = submittedValues.max_order_value;
      }
      if (
        submittedValues.order_schedule_buffer !== record?.order_schedule_buffer
      ) {
        inputs.order_schedule_buffer = submittedValues.order_schedule_buffer;
      }
      if (submittedValues.percentage_1 !== record?.percentage_1) {
        inputs.percentage_1 = submittedValues.percentage_1;
      }
      if (submittedValues.percentage_2 !== record?.percentage_2) {
        inputs.percentage_2 = submittedValues.percentage_2;
      }
      if (submittedValues.percentage_3 !== record?.percentage_3) {
        inputs.percentage_3 = submittedValues.percentage_3;
      }
      if (submittedValues.threshold_1 !== record?.threshold_1) {
        inputs.threshold_1 = submittedValues.threshold_1;
      }
      if (submittedValues.threshold_2 !== record?.threshold_2) {
        inputs.threshold_2 = submittedValues.threshold_2;
      }
      if (submittedValues.threshold_3 !== record?.threshold_3) {
        inputs.threshold_3 = submittedValues.threshold_3;
      }

      if (Object.keys(inputs)?.length > 0) {
        EditRecordAction('system-config-cruds', record?.id, inputs);
      }
    },
  });

  React.useEffect(() => {
    if (record) {
      setFieldValue('id', record?.id);
      setFieldValue('base_fare', record?.base_fare);
      setFieldValue(
        'driver_delivery_min_fare',
        record?.driver_delivery_min_fare
      );
      setFieldValue('elite_threshold', record?.elite_threshold);
      setFieldValue('fee_per_item', record?.fee_per_item);
      setFieldValue('fee_per_kilometer', record?.fee_per_kilometer);
      setFieldValue('fee_per_min_delivery', record?.fee_per_min_delivery);
      setFieldValue('fee_per_min_shop', record?.fee_per_min_shop);
      setFieldValue('fee_per_stop', record?.fee_per_stop);
      setFieldValue('first_stop_fee', record?.first_stop_fee);
      setFieldValue('gomint_order_percentage', record?.gomint_order_percentage);
      setFieldValue('max_extra_fees', record?.max_extra_fees);
      setFieldValue('order_acceptance_buffer', record?.order_acceptance_buffer);
      setFieldValue('points_per_jod', record?.points_per_jod);
      setFieldValue('support_phone', record?.support_phone);
      // setFieldValue('is_active', record?.is_active);
      setFieldValue('required_documents', record?.required_documents);
      setFieldValue('base_percentage', record?.base_percentage);
      setFieldValue('base_threshold', record?.base_threshold);
      setFieldValue('dispatching_timeout', record?.dispatching_timeout);
      setFieldValue('max_order_value', record?.max_order_value);
      setFieldValue('order_schedule_buffer', record?.order_schedule_buffer);
      setFieldValue('percentage_1', record?.percentage_1);
      setFieldValue('percentage_2', record?.percentage_2);
      setFieldValue('percentage_3', record?.percentage_3);
      setFieldValue('threshold_1', record?.threshold_1);
      setFieldValue('threshold_2', record?.threshold_2);
      setFieldValue('threshold_3', record?.threshold_3);
    }
  }, [record, setFieldValue]);
  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };

  if (!record) {
    return null;
  }
  const pageHeaderStyle = {
    borderLeft: '5px solid #34a6ff',
    height: 'min-content',
    marginBottom: '16px',
    padding: '0 3% 0 2%',
    fontSize: '1px',
  };
  const iconStyle = { fontSize: '20px' };
  const buttonStyle = {
    background: 'transparent',
    border: 'none',
    verticalAlign: '-6px',
  };
  return (
    <Edit saveButtonProps={buttonProps} title={'Edit System Config'}>
      <Form layout="vertical">
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <PageHeader title="Percentage" style={pageHeaderStyle} />
            <Form.Item label="Gomint Order Percentage">
              <InputNumber
                name="gomint_order_percentage"
                value={values.gomint_order_percentage}
                onChange={(val) =>
                  setFieldValue('gomint_order_percentage', val)
                }
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Gomint Order Percentage' represents the percentage of the order amount that the company will retain as its fee."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Base Percentage">
              <InputNumber
                name="base_percentage"
                value={values.base_percentage}
                onChange={(val) => setFieldValue('base_percentage', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Base Percentage' is the foundational rate used to calculate certain fees or commissions within the Gomint system."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Percentage 1">
              <InputNumber
                name="percentage_1"
                value={values.percentage_1}
                onChange={(val) => setFieldValue('percentage_1', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Percentage 1' is the rate applied to orders when the assigned driver is operating within the designated 'Threshold 1' area. "
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Percentage 2">
              <InputNumber
                name="percentage_2"
                value={values.percentage_2}
                onChange={(val) => setFieldValue('percentage_2', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Percentage 2' is the rate applied to orders when the assigned driver is operating within the designated 'Threshold 2' area. "
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Percentage 3">
              <InputNumber
                name="percentage_3"
                value={values.percentage_3}
                onChange={(val) => setFieldValue('percentage_3', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Percentage 3' is the rate applied to orders when the assigned driver is operating within the designated 'Threshold 3' area. "
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <PageHeader title="Fare" style={pageHeaderStyle} />
            <Form.Item label="Base Fare">
              <InputNumber
                name="base_fare"
                value={values.base_fare}
                onChange={(val) => setFieldValue('base_fare', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Base Fare' is the initial charge applied to every order in the Gomint system, serving as the starting point for calculating the total cost of a delivery."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Driver Delivery Min Fare">
              <InputNumber
                name="driver_delivery_min_fare"
                value={values.driver_delivery_min_fare}
                onChange={(val) =>
                  setFieldValue('driver_delivery_min_fare', val)
                }
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Driver Delivery Min Fare' is the minimum amount a driver will earn for completing a delivery, ensuring fair compensation for their time and effort."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <PageHeader title="Points" style={pageHeaderStyle} />
            <Form.Item label="Points Per Jod">
              <InputNumber
                name="points_per_jod"
                value={values.points_per_jod}
                onChange={(val) => setFieldValue('points_per_jod', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Points Per JOD' setting determines the number of points a driver receives for each Jordan Dinar earned from completed orders."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <PageHeader title="Fee" style={pageHeaderStyle} />
            <Form.Item label="Fee Per Item">
              <InputNumber
                name="fee_per_item"
                value={values.fee_per_item}
                onChange={(val) => setFieldValue('fee_per_item', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Fee Per Item' is the additional charge applied for each individual item included in an order. "
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Fee Per Kilometer">
              <InputNumber
                name="fee_per_kilometer"
                value={values.fee_per_kilometer}
                onChange={(val) => setFieldValue('fee_per_kilometer', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Fee Per Kilometer' represents the additional charge applied based on the distance traveled for a delivery."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Fee Per Min Delivery">
              <InputNumber
                name="fee_per_min_delivery"
                value={values.fee_per_min_delivery}
                onChange={(val) => setFieldValue('fee_per_min_delivery', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Fee Per Min Delivery' is the charge applied for the time spent during the delivery process."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Fee Per Stop">
              <InputNumber
                name="fee_per_stop"
                value={values.fee_per_stop}
                onChange={(val) => setFieldValue('fee_per_stop', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Fee Per Stop' is the additional charge applied for each designated stop made during a delivery."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="First Stop Fee">
              <InputNumber
                name="first_stop_fee"
                value={values.first_stop_fee}
                onChange={(val) => setFieldValue('first_stop_fee', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'First Stop Fee' is a specific charge applied to cover the initial stop in a multi-stop delivery. "
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Fee Per Min Shop">
              <InputNumber
                name="fee_per_min_shop"
                value={values.fee_per_min_shop}
                onChange={(val) => setFieldValue('fee_per_min_shop', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Fee Per Min Shop' is the charge applied for the time spent at a shop or store while fulfilling a delivery order."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <PageHeader title="Buffer" style={pageHeaderStyle} />
            <Form.Item label="Order Acceptance Buffer">
              <InputNumber
                name="order_acceptance_buffer"
                value={values.order_acceptance_buffer}
                onChange={(val) =>
                  setFieldValue('order_acceptance_buffer', val)
                }
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Order Acceptance Buffer' is the predefined time duration during which a driver has the opportunity to accept or decline a new delivery order. "
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Order Schedule Buffer">
              <InputNumber
                name="order_schedule_buffer"
                value={values.order_schedule_buffer}
                onChange={(val) => setFieldValue('order_schedule_buffer', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Order Schedule Buffer' is a time interval configured to maintain a minimum lead time between the current moment and the scheduled delivery time for orders. "
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <PageHeader title="Threshold" style={pageHeaderStyle} />
            <Form.Item label="Threshold 1">
              <InputNumber
                name="threshold_1"
                value={values.threshold_1}
                onChange={(val) => setFieldValue('threshold_1', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="Threshold 1' represents a specific geographical area or condition within the delivery system."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Threshold 2">
              <InputNumber
                name="threshold_2"
                value={values.threshold_2}
                onChange={(val) => setFieldValue('threshold_2', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="Threshold 2' represents a specific geographical area or condition within the delivery system."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Threshold 3">
              <InputNumber
                name="threshold_3"
                value={values.threshold_3}
                onChange={(val) => setFieldValue('threshold_3', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="Threshold 3' represents a specific geographical area or condition within the delivery system."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Base Threshold">
              <InputNumber
                name="base_threshold"
                value={values.base_threshold}
                onChange={(val) => setFieldValue('base_threshold', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="Base Threshold' is a fundamental criterion in the delivery system, defining a foundational condition that influences fees, rates, or service conditions."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Elite Threshold">
              <InputNumber
                name="elite_threshold"
                value={values.elite_threshold} //
                onChange={(val) => setFieldValue('elite_threshold', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="'Elite Threshold' establishes the minimum order count required to attain elite status."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <PageHeader title="Max" style={pageHeaderStyle} />
            <Form.Item label="Max Extra Fees">
              <InputNumber
                name="max_extra_fees"
                value={values.max_extra_fees}
                onChange={(val) => setFieldValue('max_extra_fees', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="The 'Max Extra Fees' setting establishes the upper limit for additional charges in a delivery. This ensures that the cumulative extra fees, including calculated fees and service charges, do not exceed the specified maximum."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Max Order Value">
              <InputNumber
                name="max_order_value"
                value={values.max_order_value}
                onChange={(val) => setFieldValue('max_order_value', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="Max Order Value' is the predefined upper limit set for the total value of a delivery order."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
            <Form.Item label="Dispatching Timeout">
              <InputNumber
                name="dispatching_timeout"
                value={values.dispatching_timeout}
                onChange={(val) => setFieldValue('dispatching_timeout', val)}
              />
              <Tooltip
                style={{ paddingLeft: '10px' }}
                title="Dispatching Timeout' is the predetermined duration within which a delivery must be assigned to a driver. If the system exceeds this time limit without successfully assigning a driver, it triggers a dispatching timeout."
              >
                <Button
                  style={buttonStyle}
                  icon={<InfoCircleOutlined style={iconStyle} />}
                ></Button>
              </Tooltip>
            </Form.Item>
          </ColumnWrapper>
        </DetailsWrapper>
        <ColumnWrapper screenMD={!!screens.md}>
          <PageHeader
            title="Document Management"
            style={{
              borderBottom: '5px solid #34a6ff',
              // backgroundColor: '#34a6ff',
              marginBottom: '16px',
            }}
          />
          <Form.Item label="Document English Title">
            <Input
              name="required_docs_en"
              onChange={handleChange}
              placeholder={'Add Document English Title'}
            />
          </Form.Item>
          <Form.Item label="Required Document Arabic Title">
            <Input
              name="required_docs_ar"
              onChange={handleChange}
              placeholder={'Add Document Arabic Title'}
            />
          </Form.Item>
          <Form.Item label="Remove Required Document">
            <Select
              options={(record?.required_documents || [])?.map((doc) => ({
                label: doc,
                value: doc,
              }))}
              onChange={(val) => setFieldValue('removed_doc', val)}
              placeholder={'Select Document'}
            />
          </Form.Item>
          <Form.Item label="Support Phone">
            <Input
              type="number"
              name="support_phone"
              value={values.support_phone}
              onChange={(val) => setFieldValue('support_phone', val)}
            />
          </Form.Item>
        </ColumnWrapper>
      </Form>
    </Edit>
  );
};
