import React, { useEffect, useState } from 'react';
import logo from '.././../../ images/logo.png';
import {
  Typography,
  AntdLayout,
  Row,
  Col,
  Card,
  Input,
  Button,
  Form,
} from '@pankod/refine-antd';

import styled from 'styled-components';
import { ConfigsStore } from '../../../ Store';
import { VerificationAction } from '../../../Actions/AuthActions';
import colors from '../../../ Theme/ Colors';
import { useNavigation } from '@pankod/refine-core';
const { Title } = Typography;
export const Verification: React.FC = () => {
  const [email, setEmail] = useState<string | undefined>();
  const { push } = useNavigation();
  const getEmail = async () => {
    const email = await ConfigsStore.get('resetPasswordEmail');
    setEmail(email);
  };
  useEffect(() => {
    getEmail();
  }, []);
  const navigateToResetPassword = () => {
    push('/reset-password');
  };
  const CardTitle = (
    <Title level={3} className="layout-title">
      {'Restore confirmation code  '}
    </Title>
  );
  return (
    <AntdLayout className="antdLayout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Image src={logo} width="180" alt="logo" />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={colors.primaryLight}
                requiredMark={false}
                onFinish={(val: any) => {
                  VerificationAction(val?.code, email, navigateToResetPassword);
                }}
              >
                <Form.Item
                  name="code"
                  label={' code'}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="●●●●●" />
                </Form.Item>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={colors.primaryLight}
                  style={{
                    background: 'linear-gradient( #4BC1FA 40% , #4EB0FD 50% )',
                  }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: colors.primaryLight,
                    }}
                  >
                    {'send'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
export const Image = styled.img`
  margin-bottom: 26px;
  align-self: center;
`;
